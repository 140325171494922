import React from "react"
import { GatsbyImageBackground } from "components/atoms"
import { Article } from "components/molecules"
import { StaticQuery, graphql } from "gatsby"

const Content = ({ contentName }) => {
  return (
    <StaticQuery
      query={graphql`
        query ContentQuery {
          allStrapiContent {
            nodes {
              Name
              TextColor
              TextAlign
              Width
              Width_Mobile
              Height
              Height_Mobile
              AlignItems
              JustifyContent
              articles {
                Name
                Title_en
                Title_th
                Subtitle_en
                Subtitle_th
                buttonText_en
                buttonText_th
                Action
                Width
                Width_Mobile
                AdditionalOption {
                  animation {
                    delay
                    duration
                  }
                  subtitle {
                    fontSize
                    fontWeight
                  }
                  title {
                    fontSize
                  }
                }
              }
              media {
                Media {
                  ext
                  internal {
                    description
                  }
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                Name
                Action
                VideoUrl
                Height
                Height_Mobile
                Width
                Width_Mobile
              }
              Background {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              BackgroundColor
              product {
                Name_en
              }
            }
          }
        }
      `}
      render={data => {
        const content = data.allStrapiContent.nodes.find(
          ({ Name }) => Name === contentName
        )
        return (
          <GatsbyImageBackground
            background={
              content.Background && content.Background.childImageSharp.fluid
            }
            backgroundImage={content.BackgroundColor}
            backgroundColor={content.BackgroundColor || "transparent"}
            width={[content.Width_Mobile || "90%", content.Width || "45%"]}
            height={[content.Height_Mobile || "auto", content.Height || "auto"]}
            textAlign={["center", content.TextAlign || "center"]}
            key={content.Name}
            display={"flex"}
            justifyContent={content.JustifyContent || "center"}
            alignItems={content.AlignItems || "center"}
            pt={[
              content.AlignItems === "flex-start" ? "4.75%" : 0,
              content.AlignItems === "flex-start" ? "10%" : 0,
            ]}
          >
            {content.articles.map(article => (
              <Article
                article={article}
                TextColor={content.TextColor}
                key={article.Name}
              ></Article>
            ))}
          </GatsbyImageBackground>
        )
      }}
    />
  )
}

export default Content

import React from "react"
import ReactPlayer from "react-player"

const Video = ({ videoUrl ,options }) => {
  return (
    <ReactPlayer width="100%" height="100%" url={videoUrl} {...options}/>
  )
}

export default Video

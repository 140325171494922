import get from "lodash/get"


const baseTheme = {
  fontWeights: {
    ultraLight: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    semiBold: 500,
    bold: 600,
    extraBold: 700,
    ultraBold: 800,
    black: 900,
  },
  globalShadows: {
    baseShadow: "0 5px 20px 0 rgba(0,0,0,0.3)",
    lightShadow: "0 5px 20px 0 rgba(0,0,0,0.1)",
  },
  // thaiFont: {
  //   fontFamily: 'thai-sans-neue',
  //   letterSpacing: 1.5
  // },
  colors: {
    white: "#ffffff",
    extraLightGrey: "#f5f5f5",
    veryLightGrey: "#e5e5e5",
    lightGrey: "#b5b5b5",
    grey: "#888888",
    darkGrey: "#555555",
    black: "rgba(0,0,0,0.87)",
    primary: "#2f54a6",
    lightPrimary: "#fff7ee",
    secondary: "#753878",
    delete: "#ff5252",
    success: "#38D203",
    danger: "#ff5252",
    line: "#00c300",
    lineDarker: "#009000",
  },
  palette: {
    type: "light",
    primary: {
      light: "#2B80BD",
      main: "#2f54a6",
      dark: "#2B33BD",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#753878",
      light: "#743C8F",
      dark: "#8F3C6D",
      contrastText: "#ffffff",
    },
    delete: {
      main: "#ff5252",
      light: "#ffc346",
      dark: "#f89202",
      contrastText: "#ffffff",
    },
  },
  dashboard: {
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.4)",
    normal: {
      marginTop: 30,
      borderRadius: 8,
      maxWidth: 936,
      margin: "auto",
    },
  },
  paper: {
    marginTop: 30,
    maxWidth: 936,
    margin: "auto",
    transition: "all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    header: {
      padding: 20,
    },
    padding: 20,
  },
}

export default {
  ...baseTheme,
  get: (...args) => get(baseTheme, ...args),
}

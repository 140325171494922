import React from "react"
import Grid from "@material-ui/core/Grid"
import { Block, Text } from "components/atoms"
import { Slick } from "components/molecules"
import { StaticQuery, graphql } from "gatsby"
import { MediaSection } from "components/organisms"
import withIntl from "hocs/withIntl"

const Promotion = ({ Name, locale }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiPromotion {
            nodes {
              Title_en
              Title_th
              Name
              media {
                Media {
                  ext
                  internal {
                    description
                  }
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                Name
                Action
                VideoUrl
                Height
                Height_Mobile
                Width
                Width_Mobile
              }
            }
          }
        }
      `}
      render={data => {
        const promotion = data.allStrapiPromotion.nodes.find(
          node => node.Name === Name
        )
        const title = promotion[`Title_${locale}`]
        const mediaPromotions = promotion.media
        const FirstPromotionFormat = medias => {
          if (medias.length > 1) {
            return (
              <Grid container spacing={3} justify="center" alignItems="center">
                {medias.map(media => (
                  <Grid item xs={12} md={6}>
                    <MediaSection
                      media={media}
                      fixWidthAndHeight={{
                        fixHeight: "580px",
                        fixHeight_Mobile: "auto",
                        fixWidth: "100%",
                        fixWidth_Mobile: "100%",
                      }}
                      imageStyle={{ objectFit: "cover" }}
                    />
                  </Grid>
                ))}
              </Grid>
            )
          } else {
            return (
              <Grid container spacing={10} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Block height>
                    <MediaSection
                      media={medias[0]}
                      fixWidthAndHeight={{
                        fixHeight: "580px",
                        fixHeight_Mobile: "auto",
                        fixWidth: "100%",
                        fixWidth_Mobile: "100%",
                      }}
                      imageStyle={{ objectFit: "cover" }}
                    />
                  </Block>
                </Grid>
              </Grid>
            )
          }
        }

        const SecondPromotionFormat = medias => {
          return (
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={12} md={6}>
                <MediaSection
                  media={medias[0]}
                  fixWidthAndHeight={{
                    fixHeight: "580px",
                    fixHeight_Mobile: "auto",
                    fixWidth: "100%",
                    fixWidth_Mobile: "100%",
                  }}
                  imageStyle={{ objectFit: "cover" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MediaSection
                  media={medias[1]}
                  fixWidthAndHeight={{
                    fixHeight: "290px",
                    fixHeight_Mobile: "auto",
                    fixWidth: "100%",
                    fixWidth_Mobile: "100%",
                  }}
                  imageStyle={{ objectFit: "cover" }}
                />
                <Block mt={15}>
                  {medias.length > 3 ? (
                    <Grid
                      container
                      spacing={3}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={6}>
                        <MediaSection
                          media={medias[2]}
                          fixWidthAndHeight={{
                            fixHeight: "275px",
                            fixHeight_Mobile: "auto",
                            fixWidth: "100%",
                            fixWidth_Mobile: "100%",
                          }}
                          imageStyle={{ objectFit: "cover" }}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <MediaSection
                          media={medias[3]}
                          fixWidthAndHeight={{
                            fixHeight: "275px",
                            fixHeight_Mobile: "auto",
                            fixWidth: "100%",
                            fixWidth_Mobile: "100%",
                          }}
                          imageStyle={{ objectFit: "cover" }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      spacing={10}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <MediaSection
                          media={medias[2]}
                          fixWidthAndHeight={{
                            fixHeight: "275px",
                            fixHeight_Mobile: "auto",
                            fixWidth: "100%",
                            fixWidth_Mobile: "100%",
                          }}
                          imageStyle={{ objectFit: "cover" }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Block>
              </Grid>
            </Grid>
          )
        }

        const SlickPromotionFormat = medias => {
          return (
            <Slick
              settings={{
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                responsive: [
                  {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ],
              }}
            >
              {medias.map(media => (
                <Block pr={10} pl={10}>
                  {/* <Media
                    image={media.Media.childImageSharp.fluid}
                    height={300}
                    Action={media.Action}
                  /> */}
                  <MediaSection
                    media={media}
                    fixWidthAndHeight={{
                      fixHeight: "580px",
                      fixHeight_Mobile: "auto",
                      fixWidth: "100%",
                      fixWidth_Mobile: "100%",
                    }}
                    imageStyle={{ objectFit: "cover" }}
                  />
                </Block>
              ))}
            </Slick>
          )
        }

        const renderPromotion = medias => {
          if (medias.length < 3) {
            return FirstPromotionFormat(medias)
          }
          if (medias.length > 2 && medias.length < 5) {
            return SecondPromotionFormat(medias)
          } else return SlickPromotionFormat(medias)
        }
        return (
          <Block>
            <Text p={["10px", "20px"]} textAlign={["center", "left"]}>
              <h2>{title}</h2>
            </Text>
            <Block px={["10px", "20px"]}>
              {renderPromotion(mediaPromotions)}
            </Block>
          </Block>
        )
      }}
    />
  )
}

export default withIntl()(Promotion)

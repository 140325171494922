import React from "react"
import { NoResult, Block, Loader, Text } from "components/atoms"
import CouponCard from "./CouponCard"
import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "@material-ui/core/CircularProgress/index"
import moment from "moment"

// REDUX
import { connect } from "react-redux"
import { compose } from "recompose"
import { bindActionCreators } from "redux"
import {
  selectCoupons,
  selectCouponsStatus,
  selectShouldGetMoreCoupon,
} from "modules/notification/selectors"
import { getCoupons } from "modules/notification/actions"

// import NoResult from "assets/no_result.jpg"

class CouponsSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showHistoryCoupon: false,
      showAvailableCoupon: true,
    }
  }

  componentDidMount() {
    const { coupons, brandId, getCouponsStatus } = this.props
    if ((!coupons || !coupons.length) && !getCouponsStatus.isFulfilled) {
      this.props.getCoupons({ brandId: brandId })
    }
  }

  fetchMoreData = () => {
    const { brandId } = this.props
    this.setState({ hasMore: false })
    this.props.getCoupons({ brandId: brandId }).then(data => {
      if (data.value.length < 15) this.setState({ hasMore: false })
      else this.setState({ hasMore: true })
    })
  }

  getCouponCanUse = () => {
    const { coupons } = this.props
    return coupons.filter(
      coupon =>
        !coupon.markAsUsed &&
        moment(coupon.expiredDate).isAfter(moment().format())
    )
  }

  getCouponCanNotUse = () => {
    const { coupons } = this.props
    return coupons.filter(
      coupon =>
        coupon.markAsUsed ||
        moment(coupon.expiredDate).isBefore(moment().format())
    )
  }

  render() {
    const { coupons, getCouponsStatus, shouldGetMoreCoupon } = this.props
    if (getCouponsStatus.isPending) return <Loader />
    if (getCouponsStatus.isFulfilled && coupons.length === 0)
      return <NoResult />

    // console.log("coupons", coupons)
    return (
      <Block pr={[20, 0]} pl={[20, 0]} mt={30}>
        <Text color={"#a8abb3"}>Available</Text>
        <Block maxHeight={["55vh", "44vh"]} overflow={"auto"} mt={15}>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.fetchMoreData()}
            hasMore={shouldGetMoreCoupon}
            loader={
              <Block.Flex key={"loader"} justifyContent={"center"} p={20}>
                <CircularProgress size={20} thickness={5} />
              </Block.Flex>
            }
          >
            <Block
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"flex-start"}
            >
              {coupons.length > 0 && this.getCouponCanUse().length ? (
                this.getCouponCanUse().map(coupon => (
                  <Block mb={10} mr={[0, "1%"]} ml={[0, "1%"]}>
                    <CouponCard
                      title={coupon.title}
                      image={coupon.image}
                      expiredDate={coupon.expiredDate}
                      markAsUsed={coupon.markAsUsed}
                    />
                  </Block>
                ))
              ) : (
                <NoResult margin={"auto"} />
              )}
            </Block>
          </InfiniteScroll>
        </Block>
        <Text color={"#a8abb3"} mt={30}>
          History
        </Text>
        <Block maxHeight={["55vh", "44vh"]} overflow={"auto"} mt={15}>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.fetchMoreData()}
            hasMore={shouldGetMoreCoupon}
            loader={
              <Block.Flex key={"loader"} justifyContent={"center"} p={20}>
                <CircularProgress size={20} thickness={5} />
              </Block.Flex>
            }
          >
            <Block
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"flex-start"}
            >
              {coupons.length > 0 && this.getCouponCanNotUse().length ? (
                this.getCouponCanNotUse().map(coupon => (
                  <Block mb={10} mr={[0, "1%"]} ml={[0, "1%"]}>
                    <CouponCard
                      title={coupon.title}
                      image={coupon.image}
                      expiredDate={coupon.expiredDate}
                      markAsUsed={coupon.markAsUsed}
                    />
                  </Block>
                ))
              ) : (
                <NoResult margin={"auto"} />
              )}
            </Block>
          </InfiniteScroll>
        </Block>
      </Block>
    )
  }
}

export default compose(
  connect(
    state => ({
      coupons: selectCoupons(state),
      getCouponsStatus: selectCouponsStatus(state),
      shouldGetMoreCoupon: selectShouldGetMoreCoupon(state),
    }),
    dispatch =>
      bindActionCreators(
        {
          getCoupons,
        },
        dispatch
      )
  )
)(CouponsSection)

import React from "react"
import { Block, Text } from "components/atoms"
import SubMenu from "components/molecules/SubMenu"
import { Link, navigate } from "gatsby"
import { scroller } from "react-scroll"
import { StaticQuery, graphql } from "gatsby"
import withIntl from "hocs/withIntl"

// Lodash
import get from "lodash/get"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

const Menu = ({ menuName, TextColor, locale, theme }) => {
  const primary = get(theme, "colors.primary")
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiMenu {
            nodes {
              id
              Name
              Title_en
              Title_th
              Action
              submenus {
                Name
                Text_en
                Text_th
                Action
              }
            }
          }
        }
      `}
      render={data => {
        const menu = data.allStrapiMenu.nodes.find(
          ({ Name }) => Name === menuName
        )
        const { Action, submenus } = menu
        const renderLinkMenu = () => {
          const externalPaths = ["http", "mailto", "tel"]
          const external = externalPaths.some(externalPath =>
            Action.startsWith(externalPath)
          )
          if (external) {
            return (
              <a
                href={Action}
                target="_blank"
                // rel={Action.startsWith("http") && "noopener noreferrer"}
              >
                <Text variant={"subtitle1"} color={TextColor}>
                  <h1>{menu[`Title_${locale}`]}</h1>
                </Text>
              </a>
            )
          }
          if (Action.startsWith("#")) {
            const redirect = () => {
              !!document.getElementById(Action.slice(1))
                ? scroller.scrollTo(Action.slice(1), {
                  duration: 500,
                  smooth: true,
                  offset: -50
                })
                : navigate("/", {
                  state: { scrollTo: Action.slice(1) },
                })
            }
            return (
              <Text variant={"subtitle1"} color={TextColor} onClick={redirect}>
                <h1>{menu[`Title_${locale}`]}</h1>
              </Text>
            )
          }
          return (
            <Link to={Action}>
              <Text variant={"subtitle1"} color={TextColor}>
                <h1>{menu[`Title_${locale}`]}</h1>
              </Text>
            </Link>
          )
        }
        return (
          <Block width={['30%', '20%']} p={10} my={"6px"}>
            {menu.Action ? (
              <Block
                mb={25}
                css={{
                  textDecoration: "none",
                  width: "fit-content",
                  cursor: "pointer",
                  "&:hover": {
                    ".MuiTypography-root": {
                      color: primary,
                    },
                  },
                }}
              >
                {renderLinkMenu()}
              </Block>
            ) : (
                menu[`Title_${locale}`] && <Text variant={"subtitle1"} color={TextColor} mb={25}>
                  <h1>{menu[`Title_${locale}`]}</h1>
                </Text>
              )}
            {submenus.map(subMenu => (
              <SubMenu
                content={subMenu[`Text_${locale}`]}
                Action={subMenu.Action}
                TextColor={TextColor}
                key={subMenu.Name}
                theme={theme}
              />
            ))}
          </Block>
        )
      }}
    />
  )
}

export default withIntl()(withEmotion(Menu))

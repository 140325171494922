import React from "react"
import { Block, GatsbyImage, Text } from "components/atoms"
import { StaticQuery, graphql } from "gatsby"
import withIntl from "hocs/withIntl"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

// Lodash
// import get from "lodash/get"

const RecommendedProduct = ({ productName, locale }) => {
  // const height = get(theme, "RecommendedProduct.height")
  return (
    <StaticQuery
      query={graphql`
        query ProductQuery {
          allStrapiProduct {
            nodes {
              Name_en
              Name_th
              Description_en
              Description_th
              Price
              Image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const product = data.allStrapiProduct.nodes.find(
          ({ Name_en }) => Name_en === productName
        )
        return (
          <Block width={"100%"} position={"relative"} mt={[25, 0]} mb={[25, 0]}>
            <GatsbyImage image={product.Image.childImageSharp.fluid} />
            <Block width={"100%"} textAlign={"center"} mt={16}>
              <Text fontWeight={"bold"} fontSize={"1.5rem"} lineHeight={"1em"}>
                {product[`Name_${locale}`]}
              </Text>
              <Text variant={"subtitle1"} component={"span"}>
                <p>{product[`Description_${locale}`]}</p>
              </Text>
              {product.Price && (
                <Text variant={"subtitle1"}>{product.Price} THB</Text>
              )}
            </Block>
          </Block>
        )
      }}
    />
  )
}

export default withIntl()(withEmotion(RecommendedProduct))

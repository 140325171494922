import React from "react"
import { Block, Text, CoverCard } from "components/atoms"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

const MyCard = ({ point, image }) => {
  return (
    <Block display={["block", "flex"]} p={[20, "0px"]}>
      <Block width={["auto", 470]} mb={[20, 0]}>
        <CoverCard point={point} image={image} />
      </Block>
      <Block ml={["0px", 20, 25]} width={["100%", "58%"]} mt={[28, 0]}>
        <Block
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text color={"#656565"}>Your Points</Text>
          <Text fontWeight={"bold"}>{point} Point</Text>
        </Block>
        <Block
          border={"1px solid #e3e3e3"}
          mt={["12px", "20px"]}
          mb={["12px", "20px"]}
        />
        <Text variant={"subtitle1"} mb={"5px"}>
          <h6>Conditions : </h6>
        </Text>
        <Text variant={"subtitle1"}>
          <h6>- Purchase every 100 baht get 1 Point on my card.</h6>
        </Text>
        <Text variant={"subtitle1"}>
          <h6>- Points cannot convert to cash.</h6>
        </Text>
      </Block>
    </Block>
  )
}

export default withEmotion(MyCard)

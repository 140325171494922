import React from "react"
import { Block, Text } from "components/atoms"
import { StoreCard, StoreMap, Slick } from "components/molecules"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import withIntl from "hocs/withIntl"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

// Lodash
import get from "lodash/get"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const BranchesSection = props => {
  const [activeStore, setActiveStore] = React.useState(0)
  const [slidesToShow, setSlideToShow] = React.useState(0)
  const [getSlickWidth, setGetSlickWidth] = React.useState(0)
  const [showGalleries, setShowGalleries] = React.useState(false)
  const [activeImage, setActiveImage] = React.useState(0)
  let slider = React.useRef()
  const [zoom, setZoom] = React.useState(12)
  const matches = useMediaQuery("(min-width:640px)")

  const darkGrey = get(props.theme, "colors.darkGrey")

  React.useEffect(() => {
    return matches
      ? setSlideToShow(Math.floor(getSlickWidth / 340))
      : setSlideToShow(Math.floor(getSlickWidth / 240))
  }, [getSlickWidth])

  const checkSlideToShow = toRender => {
    const { branches } = props
    const branchesLength = branches.filter(data => data.shopImage).length
    if (toRender > branchesLength) return branchesLength
    if (toRender > 0) return toRender
    else return 1
  }

  const { branches, storeText } = props
  return (
    <Block>
      <Block px={["15px", "20px", 0]}>
        <Text textAlign={["center"]} mb={[10]}>
          <h2>{get(storeText, "header")}</h2>
        </Text>
        <Block mb={25}>
          <Text textAlign={["center"]} variant={"subtitle1"} maxWidth={['inherit', 600]} m={'auto'}>{get(storeText, "description")}</Text>
        </Block>
        <StoreMap
          store={branches.filter(data => data.shopImage)[activeStore]}
          allStores={branches.filter(data => data.shopImage)}
          setActiveStore={setActiveStore}
          activeStore={activeStore}
          zoom={zoom}
          setZoom={setZoom}
          slider={slider}
          calculateSlideTo={index => {
            const allStores = branches.filter(data => data.shopImage)
            if (slidesToShow > allStores.length) return 0
            else return Math.trunc(index / slidesToShow)
          }}
        />
      </Block>
      <Block mt={[15, 30]}>
        <Slick
          settings={{
            autoplay: false,
            infinite: true,
            slidesToShow: checkSlideToShow(slidesToShow),
            slidesToScroll: checkSlideToShow(slidesToShow),
          }}
          dotsBottomPosition={-42}
          pb={[30, 50]}
          px={[15, 80]}
          leftArrowPos={-65}
          rightArrowPos={-65}
          setGetSlickWidth={setGetSlickWidth}
          slider={slider}
          dotsColor={darkGrey}
          boxShadow={"0px 1px 5px 3px rgba(78, 77, 77, 0.14)"}
        >
          {branches
            .filter(data => data.shopImage)
            .map((branch, index) => {
              return (
                <StoreCard
                  store={branch}
                  changeActiveStore={() => {
                    setActiveStore(index)
                    if (activeStore !== index) {
                      setZoom(15)
                    }
                  }}
                  showGalleries={() => {
                    console.log("work here")
                    setActiveStore(index)
                    setShowGalleries(true)
                  }}
                  activeCard={activeStore === index}
                />
              )
            })}
        </Slick>
        {showGalleries && (
          <Lightbox
            mainSrc={branches[activeStore].galleries[activeImage]}
            nextSrc={
              branches[activeStore].galleries[
              (activeImage + 1) % branches[activeStore].galleries.length
              ]
            }
            prevSrc={
              branches[activeStore].galleries[
              (activeImage - 1) % branches[activeStore].galleries.length
              ]
            }
            onCloseRequest={() => setShowGalleries(false)}
            onMovePrevRequest={() =>
              setActiveImage(
                activeImage +
                ((branches[activeStore].galleries.length - 1) %
                  branches[activeStore].galleries.length)
              )
            }
            onMoveNextRequest={() =>
              setActiveImage(
                (activeImage + 1) % branches[activeStore].galleries.length
              )
            }
          />
        )}
      </Block>
    </Block>
  )
}

export default withIntl()(withEmotion(BranchesSection))

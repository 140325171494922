import React from "react"
import { Link } from "gatsby"
import { Link as Scroll } from "react-scroll"
import { css } from "@emotion/core"
import omit from "lodash/omit"
import { GatsbyImage, Block, Image } from "components/atoms"

const Media = props => {
  const { image, imgStyle, alt, Action, widthAndHeight, ext } = props
  const { Height, Height_Mobile, Width, Width_Mobile } = widthAndHeight

  const renderImage = () => {
    const compatibleExts = [".png", ".jpg", ".jpeg"]
    const compatible = compatibleExts.some(
      compatibleExt => ext && ext.toLowerCase() === compatibleExt
    )
    if (compatible) {
      return (
        <GatsbyImage
          image={image}
          imgStyle={imgStyle}
          alt={alt}
          {...omit(props, ["image", "imgStyle", "alt", "actionPath"])}
          width={[
            Width_Mobile
              ? Width_Mobile.includes("%")
                ? "100%"
                : Width_Mobile
              : "100%",
            Width
              ? Width.includes("%")
                ? "100%"
                : Width
              : props.width || "100%",
          ]}
          height={[
            Height_Mobile
              ? Height_Mobile.includes("%")
                ? "100%"
                : Height_Mobile
              : "100%",
            Height
              ? Height.includes("%")
                ? "100%"
                : Height
              : props.height || " 100%",
          ]}
        />
      )
    }
    return (
      <Image
        src={image}
        width={[Width_Mobile || "100%", Width || props.width || "100%"]}
        height={[Height_Mobile || "100%", Height || props.height || " 100%"]}
      />
    )
  }

  const renderLink = () => {
    if (Action) {
      const externalPaths = ["http", "mailto", "tel"]
      const external = externalPaths.some(externalPath =>
        Action.startsWith(externalPath)
      )
      if (external) {
        return (
          <a
            href={Action}
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
          >
            {renderImage()}
          </a>
        )
      }
      if (Action.startsWith("#")) {
        return (
          <Block
            css={css`
              cursor: pointer;
            `}
          >
            <Scroll to={Action.slice(1)} smooth={true} duration={500} offset={-50}>
              {renderImage()}
            </Scroll>
          </Block>
        )
      }
      return (
        <Link to={Action} style={{ textDecoration: "none" }}>
          {renderImage()}
        </Link>
      )
    }
    return renderImage()
  }
  return <Block>{renderLink()}</Block>
}

export default Media

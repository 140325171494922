import React from 'react';
// import { Link } from 'react-router-dom';
import styled from '@emotion/styled'
import { navigate } from "gatsby"
import Grid from '@material-ui/core/Grid';

// COMPONENTS
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { NoResult, Loader, Text, Image } from 'components/atoms';
// ICONS
import Arrow from '@material-ui/icons/KeyboardArrowRight';
import Gift from '@material-ui/icons/CardGiftcard';
import Check from '@material-ui/icons/Check';


const Wrapper = styled('div')({
  position: 'relative'
});

class SurveyLists extends React.Component {
  state = { anchorEl: null };

  renderIcon = (survey) => {
    if (survey.markAsUsed) {
      return <Text fontSize={15}>ใช้สิทธิแล้ว</Text>;
    } else {
      if (survey.markAsSubmitted) {
        return survey.reward ? (
          <IconButton style={{ pointerEvents: this.checkToSurveyPage(survey) }}>
            <Gift style={{ fontSize: 33 }} />
          </IconButton>
        ) : (
          <Check />
        );
      } else {
        return (
          <IconButton style={{ pointerEvents: this.checkToSurveyPage(survey) }}>
            <Arrow style={{ fontSize: 33 }} />
          </IconButton>
        );
      }
    }
  };

  checkToSurveyPage = survey => {
    if ((survey.markAsSubmitted && !survey.reward) || survey.markAsUsed) {
      return 'none';
    } else return 'auto';
  };

  renderSurveyImage =(survey)=>{
    const {image, reward, brandLogo} = this.props
    if(!!image && image !=='')
    {
      return image
    }
    else if(reward)
    {
      return reward.image
    }
    return brandLogo
  }

  render() {
    const {  surveys, loadingSurveys, uid } = this.props;
    if (loadingSurveys) {
      return <Wrapper>{loadingSurveys && <Loader text={'กำลัง'} />}</Wrapper>;
    }
    return (
      <Wrapper>
        {surveys && surveys.length > 0 ? (
          <Grid container spacing={24}>
            {surveys.map(survey => (
              <Grid item xs={12} md={6}>
              <ListItem
                key={survey.id}
                dense
                button
                // component={Link}
                // to={`/survey?brandId=${survey.brandId}&surveyId=${survey.id}`}
                    onClick={() => {
                      survey.type === 'survey' ? navigate(`/Survey?surveyId=${survey.id}`) : 
                      navigate(`/NotiDetailPage?notificationId=${survey.id}`)}}
                style={{ pointerEvents: this.checkToSurveyPage(survey) }}>
                
                <Image
                  circular
                  objectFit={'cover'}
                  width={60}
                  height={60}
                  src={survey.image}
                  mr={20}
                />
                <ListItemText
                  primary={survey.title}
                  secondary={survey.subtitile}
                />
                {this.renderIcon(survey, uid)}
              </ListItem>
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoResult />
        )}
      </Wrapper>
    );
  }
}

export default SurveyLists;

import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';

// COMPONENTS
import { Text, Image, Button, Block } from 'components/atoms';
import { Modal } from 'components/molecules';

const Reward = styled('div')({
  marginBottom: 20,
  display: 'flex',
  overflow: 'hidden',
  '.pumpkin-image': {
    borderRadius: 5,
    width: 70,
    height: 70
  },
  '.detail': {
    textAlign: 'left',
    marginLeft: 10,
    flex: 1,
    '.title, .description': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }
});

class SurveyModal extends React.Component {
  render() {
    const { open, onClose, survey } = this.props;
    return (
      <Modal
        open={open}
        title={''}
        content={
          survey &&
          (survey.reward ? (
            <Block textAlign={'center'}>
              <Text fontSize={'1.3rem'}>ทำแบบสอบถามกับร้าน</Text>
              <Text fontSize={'1.5rem'} fontWeight="bold">
                {survey.brandName}
              </Text>
              <br />
              <Text fontSize={'1.3rem'} color={'lightGrey'}>
                เพื่อรับของรางวัล
              </Text>
              <Reward>
                <Image src={survey.reward.image} />
                <Block className={'detail'}>
                  <Text className={'title'} fontWeight={'bold'}>
                    {survey.reward.title}
                  </Text>
                  <Text className={'description'}>
                    {survey.reward.description}
                  </Text>
                </Block>
              </Reward>
              <Button.Contain
                rounded
                style={{
                  backgroundColor: survey.brandColor,
                  marginTop: 15
                }}
                onClick={() => {
                  onClose();
                  navigate(`/Survey?surveyId=${survey.id}`);
                }}>
                เริ่มทำแบบสอบถาม
              </Button.Contain>
            </Block>
          ) : (
            <Block textAlign={'center'}>
              <Text>ร้าน</Text>
              <Text fontSize={'1.5rem'} fontWeight="bold">
                {survey.brandName}
              </Text>
              <Text>
                ขอรบกวนเวลาคุณลูกค้าทำแบบสอบถาม เพื่อนำไปพัฒนาให้ดีขึ้น
              </Text>
              <Button.Contain
                rounded
                style={{
                  backgroundColor: survey.brandColor,
                  marginTop: 15
                }}
                onClick={() => {
                  onClose();
                  navigate(`/Survey?surveyId=${survey.id}`);
                }}>
                เริ่มทำแบบสอบถาม
              </Button.Contain>
            </Block>
          ))
        }
        onClose={() => onClose()}
      />
    );
  }
}

export default SurveyModal;

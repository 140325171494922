import React from "react"
import { Block } from "components/atoms"
import { StaticQuery, graphql } from "gatsby"
import { Section, Content } from "components/organisms"
import { Slick } from "components/molecules"

const Slider = ({ Name, height, contentName }) => {
  return (
    <StaticQuery
      query={graphql`
        query myQuery {
          allStrapiSlider {
            nodes {
              Name
              sections {
                Name
              }
              contents {
                Name
              }
            }
          }
        }
      `}
      render={data => {
        const sliderData = data.allStrapiSlider.nodes.find(
          node => node.Name === Name
        )
        const sections = sliderData.sections
        const contents = sliderData.contents.filter(
          content => content.Name !== contentName
        )
        if (sections.length > 0) {
          return (
            <Block width={"100%"}>
              {sections.length > 1 ? (
                <Slick
                  settings={{ autoplay: false, infinite: true, fade: true }}
                >
                  {sections.map(({ Name }) => (
                    <Section
                      sectionName={Name}
                      key={Name}
                      height={height}
                      slider={true}
                    />
                  ))}
                </Slick>
              ) : (
                <Block>
                  <Section sectionName={sections[0].Name} height={height} />
                </Block>
              )}
            </Block>
          )
        }
        if (contents.length > 0) {
          return (
            <Block width={"100%"}>
              {contents.length > 1 ? (
                <Slick
                  settings={{ autoplay: false, infinite: true, fade: true }}
                   leftArrowPos={30}
                   rightArrowPos={30}
                >
                  {contents.map(({ Name }) => (
                    <Content contentName={Name} key={Name} height={height} />
                  ))}
                </Slick>
              ) : (
                <Block>
                  <Content contentName={contents[0].Name} />
                </Block>
              )}
            </Block>
          )
        }
        return null
      }}
    />
  )
}

export default Slider

/* eslint-disable max-len */
import get from "lodash/get"
// import find from 'lodash/find';

export const getNotificationsState = state => get(state, "notification", {})
export const selectData = state => getNotificationsState(state).data
export const selectNotification = state => notificationId => {
  const result = getNotificationsState(state).data.find(
    notification => notification.id === notificationId
  )
  // console.log(' result : ', result, ' from notificationId : ', notificationId, 'type : ', type)
  // console.log(' getNotificationsState(state)[type] : ', getNotificationsState(state)[type])
  return result
}
export const selectAnnouncementsStatus = state =>
  getNotificationsState(state).getAnnouncementsStatus
export const selectNewNotificationStatus = state =>
  getNotificationsState(state).getNewNotificationStatus
export const selectBenefitsStatus = state =>
  getNotificationsState(state).getBenefitsStatus
export const selectAnnouncements = state =>
  getNotificationsState(state).data.filter(data => data.type === "announcement")

export const selectSurveysStatus = state =>
  getNotificationsState(state).getSurveysStatus

export const selectNotificationByType = state => type =>
  type ? getNotificationsState(state)[type] : []
export const selectNotificationStatusByType = state => type => {
  switch (type) {
    case "coupons":
      return getNotificationsState(state).getCouponsStatus
    case "promotions":
      return getNotificationsState(state).getPromotionsStatus
    default:
      return {
        error: null,
      }
  }
}

// export const selectAnnouncements = state =>
//   getNotificationsState(state).data.filter(
//     data => data.type === 'announcement',
//   );
export const selectCoupons = state => getNotificationsState(state).coupons
export const selectCouponsStatus = state =>
  getNotificationsState(state).getCouponsStatus
export const selectPromotions = state => getNotificationsState(state).promotions
export const selectSurveys = state => {
  return getNotificationsState(state).data.filter(
    data => data.type === "survey"
  )
}

export const selectGetAllNotificationStatus = state =>
  getNotificationsState(state).getAllNotificationStatus

export const selectShouldGetMoreAllNotification = state =>
  getNotificationsState(state).shouldGetMoreAllNotification

export const selectShouldGetMoreCoupon = state =>
  getNotificationsState(state).shouldGetMoreCoupon

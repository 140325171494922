import styled from "@emotion/styled"

const ButtonGroup = styled("div")(
  {
    ":not(:first-child)": {
      marginLeft: 15,
    },
    ".button.outlined": {
      height: "100%",
      borderRadius: 0,
      "&:first-child": {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
      },
      "&:not(:first-child)": {
        marginLeft: -1,
      },
      "&:last-child": {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      },
    },
  },
  ({ theme }) => ({
    ".button.active": {
      zIndex: 1,
      borderColor: theme.get("colors.primary"),
    },
    ".button:not(.active)": {
      color: theme.get("colors.lightGrey"),
      borderColor: "rgba(136, 136, 136, 0.2)",
    },
  })
)

export default ButtonGroup

import React from "react"
import get from "lodash/get"
import pick from "lodash/pick"
import { Block } from "components/atoms"
import { Media, Video } from "components/molecules"

const MediaSection = ({ media, imageStyle = null, fixWidthAndHeight }) => {
  const { Action, VideoURL, Height, Height_Mobile, Width, Width_Mobile } = media
  const { ext, internal, childImageSharp } = pick(media.Media, [
    "ext",
    "internal",
    "childImageSharp",
  ])
  const { fixHeight, fixHeight_Mobile, fixWidth, fixWidth_Mobile } = pick(
    fixWidthAndHeight,
    ["fixHeight", "fixHeight_Mobile", "fixWidth", "fixWidth_Mobile"]
  )
  const url = get(internal, "description", "").slice(6, -1)
  if ((ext && ext.toLowerCase() === ".mp4") || VideoURL) {
    return (
      <Block
        width={[
          fixWidth_Mobile || Width_Mobile || "100%",
          fixWidth || Width || "100%",
        ]}
        height={[
          fixHeight_Mobile || Height_Mobile || "100%",
          fixHeight || Height || " 100%",
        ]}
      >
        <Video
          videoUrl={VideoURL || url}
          options={
            VideoURL
              ? { controls: true }
              : {
                  muted: true,
                  loop: true,
                  playing: true,
                }
          }
        />
      </Block>
    )
  }
  return (
    <Block
      width={[Width_Mobile || "100%", Width || "100%"]}
      height={[Height_Mobile || "100%", Height || " 100%"]}
      m={["auto", "inherit"]}
    >
      <Media
        image={get(childImageSharp, "fluid") || url}
        Action={Action}
        widthAndHeight={{
          Height: fixHeight || Height,
          Height_Mobile: fixHeight_Mobile || Height_Mobile,
          Width: fixWidth || Width,
          Width_Mobile: fixWidth_Mobile || Width_Mobile,
        }}
        imageStyle={imageStyle}
        ext={ext}
        imageStyle={imageStyle}
      />
    </Block>
  )
}

export default MediaSection

import React from "react"
import { auth } from "firebases/config"
import { Image, Block, Text } from "components/atoms"
import CircularProgress from "@material-ui/core/CircularProgress"
import { navigate } from "gatsby"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

// REDUX
import { getProfile } from "modules/auth/actions"
import { connect } from "react-redux"
import { selectGetAuthStatus, selectAuthData } from "modules/auth/selectors"
import { bindActionCreators } from "redux"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

// Lodash
import get from "lodash/get"
class ProfileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isUser: false,
      anchorEl: null,
      width: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    const { getProfile, profile } = this.props
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ isUser: true })
        if (!profile) {
          getProfile()
        }
      } else this.setState({ isUser: false })
    })
    this.setState({ width: window.innerWidth })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  renderProfileButton = () => {
    const { anchorEl } = this.state
    const { profile, theme } = this.props
    const primary = get(theme, "colors.primary")
    const isOpen = Boolean(anchorEl)
    return (
      <Block>
        <Image
          css={{
            cursor: "pointer",
            ":hover > *": { color: `${primary} !important` },
          }}
          circular
          objectFit={"cover"}
          src={profile.photoURL}
          style={{
            border: `${primary}  2px solid`,
            height: 40,
            width: 40,
          }}
          onClick={e => {
            this.handleMenu(e)
            // if (inNavigator) return this.handleMenu(e)
            // else width > 600 ? this.handleMenu(e) : navigate("/MyAccount")
          }}
        />
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MenuItem
            onClick={() => {
              this.setState({ anchorEl: null })
              navigate("/MyAccount")
            }}
          >
            My Account
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ anchorEl: null })
              return auth.signOut().then(() => navigate("/login"))
            }}
          >
            Log Out
          </MenuItem>
        </Menu>
      </Block>
    )
  }

  renderLoginButton = () => (
    <Block
      display={["flex", "flex"]}
      css={{
        ".MuiTypography-body1": {
          cursor: "pointer",
        },
      }}
      position={["absolute", "relative"]}
      right={"16px"}
    >
      <Block display={["none", "block"]}>
        <Text
          mr={["3px", "7px"]}
          onClick={() =>
            navigate("/login", {
              state: { formState: "login" },
            })
          }
        >
          Join
        </Text>
      </Block>
      <Block display={["none", "block"]}>
        <Text> | </Text>
      </Block>
      <Text
        ml={["3px", "7px"]}
        onClick={() =>
          navigate("/login", {
            state: { formState: "login" },
          })
        }
      >
        Login
      </Text>
    </Block>
  )

  loading = () => {
    const { getProfileStatus } = this.props
    const { isUser } = this.state

    if (getProfileStatus.isPending) {
      return (
        <CircularProgress style={{ height: 44, width: 44 }}>
          {this.loading}
        </CircularProgress>
      )
    } else if (getProfileStatus.isFulfilled && isUser)
      return this.renderProfileButton()
    else return this.renderLoginButton()
  }

  render() {
    return this.loading()
  }
}

const mapStateToProps = state => ({
  getProfileStatus: selectGetAuthStatus(state),
  profile: selectAuthData(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfile,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEmotion(ProfileMenu))

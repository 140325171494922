import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Block, Text } from "components/atoms"
import { AlternateEmail, Phone, AccessTime } from "@material-ui/icons"
import { css } from "@emotion/core"

export default () => (
  <StaticQuery
    query={graphql`
      query ContactUsQuery {
        strapiContactus(Name: { eq: "Contact Us" }) {
          Address
          Tel
          Email
          OpeningHours
        }
      }
    `}
    render={data => {
      const { Address, Tel, Email, OpeningHours } = data.strapiContactus
      const style = css`
        a {
          display: flex;
          margin-bottom: 0.5rem;
          text-decoration: none;
          align-items: center;
        }
        a:hover {
          .pumpkin-text {
            color: gold;
          }
          svg {
            color: gold;
          }
        }
        svg {
          color: #636363;
        }
      `
      return (
        <Block
          width={["initial", "100%"]}
          mx={"auto"}
          my={[30, 60]}
          px={[20, 0]}
          maxWidth={1200}
        >
          <Block
            id={"Contact Us"}
            borderRadius={14}
            boxShadow={"0 2px 54px 0 rgba(0,0,0,0.06)"}
            backgroundColor={"#ffffff"}
            display={"flex"}
            justifyContent={"space-around"}
            flexDirection={["column", "row"]}
            p={[30, 40]}
            css={style}
          >
            <Text pr={10} fontWeight={"bold"} textAlign={["center", "start"]}>
              <h5>Contact Us</h5>
            </Text>
            <Block
              display={"flex"}
              maxWidth={["100%", "60%"]}
              flexDirection={["column", "row"]}
            >
              <Text
                pb={[10, 0]}
                px={[null, 20, 20, 40]}
                borderRight={[null, "2px solid #979797"]}
                variant={"subtitle2"}
              >
                {Address}
              </Text>
              <Block px={[null, 20, 20, 40]} minWidth={"45%"}>
                <a href={`tel:${Tel}`}>
                  <Phone />
                  <Text variant={"subtitle2"} ml={10}>
                    {Tel}
                  </Text>
                </a>
                <a href={`mailto:${Email}`}>
                  <AlternateEmail />
                  <Text variant={"subtitle2"} ml={10}>
                    {Email}
                  </Text>
                </a>
                <Block display={"flex"} alignItems={"center"}>
                  <AccessTime />
                  <Text variant={"subtitle2"} ml={10}>
                    {OpeningHours}
                  </Text>
                </Block>
              </Block>
            </Block>
          </Block>
        </Block>
      )
    }}
  />
)

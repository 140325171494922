import React from "react"
import get from "lodash/get"
import { css } from "@emotion/core"
import { StaticQuery, graphql } from "gatsby"
import isEmpty from "lodash/isEmpty"

import { db } from "firebases/config"

import { Modal, SocialMedia } from "components/molecules"
import { Text, Image, Block } from "components/atoms"

const TransactionModal = ({ profile, transaction, setTransactionNull }) => {
  return (
    <StaticQuery
      query={graphql`
        query SocailQuery {
          strapiFooter(Name: { eq: "Footer" }) {
            socialmedias {
              Name
              Logo {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
              Action
            }
          }
        }
      `}
      render={data => {
        const socialmedias = get(data, "strapiFooter.socialmedias")
        const renderContent = ({
                                 type,
                                 campaign,
                                 brand = {},
                                 reward = {},
                                 point,
                                 newPoint,
                                 operation,
                               }) => {
          if (type === "redemption" && operation === "void") {
            return (
              <div style={{ textAlign: "center" }}>
                <Text>
                  <h3>
                    พนักงานได้ทำการยกเลิก
                  </h3>
                </Text>
                <Text variant={"subtitle1"} mb={"8px"}>
                  การแลกของรางวัล
                </Text>
                <Image
                  src={reward.image}
                  style={{
                    marginTop: 10,
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: 80,
                    height: 80,
                    borderRadius: 10,
                  }}
                />
                <Text variant={"subtitle1"} mt={"8px"}>
                  {reward.title}
                </Text>
                <Text
                  display={"inline-block"}
                  fontSize={"1.5em"}
                  fontWeight={"bold"}
                >
                  Remaining Point:&nbsp;
                </Text>
                <Text
                  display={"inline-block"}
                  fontSize={"1.5em"}
                  fontWeight={"bold"}
                  color={brand.primaryColor}
                >
                  {newPoint}
                </Text>
              </div>
            )
          }
          if (type === "redemption") {
            if (campaign && !isEmpty(campaign)) {
              return (
                <Block textAlign={"center"}>
                  <Text>
                    <h3>
                      คุณได้รับรางวัลจาก
                    </h3>
                  </Text>
                  <Text variant={"subtitle1"} color="primary" fontWeight={"bold"}>
                    {brand.name}
                  </Text>
                  <Image
                    src={campaign.image}
                    style={{
                      marginTop: 10,
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: 80,
                      height: 80,
                      borderRadius: 10,
                    }}
                  />
                  <Text variant={"subtitle1"} mt={"8px"}>
                    {campaign.title}
                  </Text>
                </Block>
              )
            }
            return (
              <Block textAlign={"center"}>
                <Text>
                  <h3>
                    You Redeemed
                  </h3>
                </Text>
                <Text variant={"subtitle1"} mb={"8px"}>
                  {reward.title}
                </Text>
                <Image
                  src={reward.image}
                  margin={"10px auto"}
                  width={80}
                  height={80}
                  borderRadius={8}
                  border={`solid 2px ${brand.primaryColor}`}
                  objectFit={"cover"}
                />
                <Text
                  display={"inline-block"}
                  fontSize={"1.5em"}
                  fontWeight={"bold"}
                >
                  Remaining Point:&nbsp;
                </Text>
                <Text
                  display={"inline-block"}
                  fontSize={"1.5em"}
                  fontWeight={"bold"}
                  color={brand.primaryColor}
                >
                  {newPoint}
                </Text>
              </Block>
            )
          }
          if (type === "transaction" && operation === "void") {
            return (
              <Block textAlign={"center"}>
                <Text>
                  <h3>
                    พนักงานได้ทำการลบแต้มของคุณ
                  </h3>
                </Text>
                <Text variant={"subtitle1"} mb={"8px"}>
                  จำนวน
                </Text>
                <Block
                  borderRadius={8}
                  backgroundColor={"#f2f2f2"}
                  display={"inline-block"}
                  p={"8px 20px"}
                >
                  <Text lineHeight={1}>
                    <h3>
                      {point}
                    </h3>
                  </Text>
                  <Text fontSize={"1em"} color={"grey"}>
                    Point
                  </Text>
                </Block>
              </Block>
            )
          }
          return (
            <Block textAlign={"center"}>
              <Text>
                <h3>
                  Congratulations!
                </h3>
              </Text>
              <Text variant={"subtitle1"} mb={"8px"}>
                You Got
              </Text>
              <Block
                borderRadius={8}
                backgroundColor={"#f2f2f2"}
                display={"inline-block"}
                p={"8px 20px"}
              >
                <Text lineHeight={1}>
                  <h3>
                    {point}
                  </h3>
                </Text>
                <Text fontSize={"1em"} color={"grey"}>
                  Point
                </Text>
              </Block>
            </Block>
          )
        }

        return (
          <Modal
            logo={transaction.brand.logo}
            color={transaction.brand.primaryColor}
            open
            className={css({
              "&.modal .actions": {
                margin: 0,
              },
            })}
            content={
              <Block>
                {renderContent(transaction)}
              </Block>
            }
            onClose={() => {
              db.ref(`/transactions/${get(profile, "uid")}`).remove()
              setTransactionNull()
            }}
            actions={
              <Block
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                minWidth={["100%", "23vw"]}
                width={"100%"}
              >
                <Text variant={"subtitle1"}>FOLLOW US ON:</Text>
                <Block display={"flex"}>
                  <SocialMedia socialMedia={socialmedias} margin={"5.5px"} />
                </Block>
              </Block>
            }
          />
        )
      }}
    />
  )
}

export default TransactionModal

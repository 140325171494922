import React from "react"
import omit from "lodash/omit"
import PropTypes from "prop-types"
import {
  compose,
  typography,
  space,
  color,
  flexbox,
  layout,
  grid,
  background,
  border,
  position,
  shadow,
  letterSpacing
} from "styled-system"
import styled from "@emotion/styled"
import Typography from "@material-ui/core/Typography"
import { ClassNames } from "@emotion/core"
import Heading from "./Heading"
import { FormattedMessage } from "react-intl"

const Text = styled(
  ({ className, inline, messageId, values, children, ...props }) => (
    <ClassNames>
      {({ cx }) => (
        <Typography
          className={cx("pumpkin-text", className, inline && "inline")}
          {...omit(props, [
            "color",
            "textAlign",
            "danger",
            "lineHeight",
            "opacity",
          ])}
        >
          {messageId ? (
            <FormattedMessage id={messageId} values={values} />
          ) : (
            children
          )}
        </Typography>
      )}
    </ClassNames>
  )
)`
  &.pumpkin-text.inline {
    display: inline-block;
  }
  &.pumpkin-text {
    ${compose(
      typography,
      space,
      color,
      flexbox,
      layout,
      grid,
      background,
      border,
      position,
      shadow,
      letterSpacing
    )}
})}

  }
`

Text.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      "red",
      "white",
      "extraLightGrey",
      "lightGrey",
      "grey",
      "darkGrey",
      "black",
      "primary",
      "secondary",
      "inherit",
      "#FFFFFF",
    ]),
    PropTypes.string,
  ]),
  fontWeight: PropTypes.oneOfType([
    PropTypes.oneOf([
      "ultraLight",
      "extraLight",
      "light",
      "regular",
      "semiBold",
      "bold",
      "extraBold",
      "ultraBold",
      "black",
    ]),
    PropTypes.number,
  ]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  danger: PropTypes.bool,
}
Text.defaultProps = {
  // color: "white",
  danger: false,
}

Text.Heading = Heading

export default Text

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Block, Text, GatsbyImage } from "components/atoms"
import withIntl from "hocs/withIntl"



const useStyles = makeStyles({
  card: {
    height: "100%",
    textAlign: "center",
  },
  CardActionArea: {
    height: "100%",
  },
})

const LandscapeProductCard = ({
  product,
  locale,
  // productCardStyle,
  mobileWidth,
}) => {
  const classes = useStyles()
  // const nameFontSize = get(productCardStyle, "nameFontSize")
  // const priceFontSize = get(productCardStyle, "priceFontSize")
  return (
    <Block width={[mobileWidth, 272]} height={[230, 365]} mx={"auto"} pb={'15px'}>
      <Card className={classes.card}>
        <Block height={"100%"}>
          <GatsbyImage
            image={product.Image.childImageSharp.fluid}
            height={[114, 203]}
            width={"100%"}
            imageStyle={{ objectFit: "cover" }}
          />
          <CardContent>
            <Block
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              css={{
                h6: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
                p: {
                  width: "100%",
                },
              }}
            >
              <Text variant={"h5"}>
                {product[`Name_${locale}`]}
              </Text>
            </Block>
            <Block
              overflow={"hidden"}
              css={{
                textOverflow: "ellipsis",
              }}
              pt={['6px', '10px']}
            >
              <Text
                variant={"h6"}
                component={"span"}
              >
                {product[`Description_${locale}`]}
              </Text>
            </Block>
          </CardContent>
        </Block>
      </Card>
    </Block>
  )
}

export default withIntl()(LandscapeProductCard)

import React from "react"
import { Block, GatsbyImage } from "components/atoms"
import { ProfileMenu } from "components/molecules"
import { Navigator } from "components/organisms"
import { StaticQuery, graphql } from "gatsby"
import SubMenu from "components/molecules/SubMenu"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { Link } from "gatsby"
import withIntl from "hocs/withIntl"

import { Menu } from "@material-ui/icons"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

const Header = ({
  inIndexPage,
  switchLanguage,
  locale,
  theme,
  crmOption = "available",
}) => {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiHeader(Name: { eq: "Header" }) {
            Name
            Layout
            TextColor
            submenus {
              Name
              Text_en
              Text_th
              Action
            }
            Logo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={data => {
        const Header = data.strapiHeader
        const subMenus = Header.submenus
        const logo = Header.Logo
        const layout = Header.Layout
        const TextColor = Header.TextColor
        const renderSubMenus = subMenus => {
          if (layout === "Left")
            return { subMenusRights: subMenus, subMenusLefts: [] }
          else {
            const halfCount = subMenus.length / 2
            return {
              subMenusLefts: subMenus.slice(0, halfCount),
              subMenusRights: subMenus.slice(halfCount, subMenus.length),
            }
          }
        }

        const renderSwitchLanguage = () => {
          return (
            <Block
              display={["none", "flex"]}
              flex={1}
              css={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: 30,
                },
                ".MuiSelect-outlined": {
                  padding: "10px 40px 10px 16px",
                },
                ".MuiSelect-icon": {
                  marginRight: 8,
                  marginTop: 2,
                },
                ".MuiSelect-select:focus": {
                  background: "white",
                },
              }}
            >
              <FormControl variant="outlined">
                <Select
                  native
                  value={locale}
                  input={
                    <OutlinedInput
                      name="age"
                      // labelWidth={labelWidth}
                      id="outlined-age-native-simple"
                    />
                  }
                  onChange={event => switchLanguage(event.target.value)}
                >
                  <option value={"en"}>English</option>
                  <option value={"th"}>Thai</option>
                </Select>
              </FormControl>
            </Block>
          )
        }

        const { subMenusLefts, subMenusRights } = renderSubMenus(subMenus)
        return (
          <Block
            id={"header"}
            position={inIndexPage ? "fixed" : "initial"}
            background={"white"}
            top={0}
            css={{
              transition: "0.3s",
              zIndex: 1000,
              width: "100%",
              boxShadow: "0 5px 20px 0 rgba(0,0,0,0.1)",
            }}
          >
            <Block
              display={"flex"}
              maxWidth={["initial", 700, 960, 1200]}
              width={["100%", "90vw"]}
              mx={"auto"}
              alignItems={"center"}
              justifyContent={layout === "Center" ? "initial" : "space-around"}
            >
              {layout === "Center" && renderSwitchLanguage()}
              <Block.Middle
                px={[16, 0]}
                position={"relative"}
                minHeight={[64, 80]}
                justifyContent={["flex-start", "space-around"]}
                width={layout === "Center" ? ["100%", "65%"] : ["100%", "78%"]}
              >
                <Block
                  display={["block", "none", "none"]}
                  css={{ ".MuiSvgIcon-root": { fontSize: 24 } }}
                >
                  <Menu onClick={() => setShowMobileMenu(true)} />
                </Block>
                {subMenusLefts.length > 0 && (
                  <Block width={"35%"} display={["none", "block"]}>
                    <Block.Middle
                      alignSelf={"stretch"}
                      justifyContent={"space-around"}
                    >
                      {subMenusLefts.map(subMenu => (
                        <Block key={subMenu.Name}>
                          <SubMenu
                            content={subMenu[`Text_${locale}`]}
                            Action={subMenu.Action}
                            TextColor={TextColor}
                            theme={theme}
                          />
                        </Block>
                      ))}
                    </Block.Middle>
                  </Block>
                )}
                <Block width={"30%"} m={["auto", "none"]}>
                  <Link to="/">
                    <GatsbyImage
                      image={logo.childImageSharp.fluid}
                      width={[85, 100]}
                      m={["none", layout === "Center" ? "auto" : "none"]}
                      imageStyle={{ objectFit: "contain" }}
                    />
                  </Link>
                </Block>
                <Block
                  display={["contents", "none"]}
                  flex={1}
                  justifyContent={"flex-end"}
                >
                  {crmOption === "available" && <ProfileMenu />}
                </Block>
                <Block.Middle
                  alignSelf={"stretch"}
                  justifyContent={"space-around"}
                  width={layout === "Center" ? "35%" : "70%"}
                  display={["none", "flex"]}
                >
                  {subMenusRights.map(subMenu => (
                    <Block key={subMenu.Name}>
                      <SubMenu
                        content={subMenu[`Text_${locale}`]}
                        Action={subMenu.Action}
                        TextColor={TextColor}
                        theme={theme}
                      />
                    </Block>
                  ))}
                </Block.Middle>
              </Block.Middle>
              {layout === "Left" && renderSwitchLanguage()}
              <Block
                display={["none", "flex"]}
                flex={1}
                justifyContent={"flex-end"}
              >
                {crmOption === "available" && <ProfileMenu />}
              </Block>
            </Block>
            <Block>
              <Navigator
                items={subMenus.map(data => {
                  return { ...data, Text: data[`Text_${locale}`] }
                })}
                open={showMobileMenu}
                onClose={() => setShowMobileMenu(false)}
                TextColor={TextColor}
                locale={locale}
                switchLanguage={switchLanguage}
                crmOption={crmOption}
              />
            </Block>
          </Block>
        )
      }}
    />
  )
}

export default withIntl()(withEmotion(Header))

/** @jsx jsx */
import React from "react"
import PropTypes from "prop-types"
import { jsx, css } from "@emotion/core"

// COMPONENTS
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import { Block, Text, Image } from "components/atoms"
import CouponCard from "../CouponsSection/CouponCard"

// ICONS
import { Close } from "@material-ui/icons"

import Background from "assets/email2.png"

// HELPERS

const WelcomeModal = ({ className, open = true, onClose, coupon }) => {
  const { reward, brandName, expiredDate, markAsUsed } = coupon
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      className={className}
      css={css`
        .MuiPaper-root {
          background: transparent;
          box-shadow: none;
          overflow-y: unset;
          margin: 0px;
          width: 100%;
          margin: 0;
          top: 20%;
          position: absolute;
        }
        .couponCard {
          margin: auto;
        }
      `}
    >
      <Block position={"relative"} width={[320, 420]} m={"auto"}>
        <Block
          margin={"auto"}
          ml={[13, 17]}
          height={355}
          width={[276, 367]}
          background={"#f6f6f6"}
          borderRadius={20}
          p={10}
          position={"relative"}
        >
          <Block
            position={"absolute"}
            right={8}
            top={5}
            css={{
              ".MuiSvgIcon-root": {
                fontSize: 16,
                color: "white",
              },
              ".MuiIconButton-root": {
                background: "#ebebeb",
                padding: 5,
              },
            }}
          >
            <IconButton onClick={() => onClose()} aria-label="Close">
              <Close />
            </IconButton>
          </Block>
          <Text textAlign="center" variant="subtitle1" fontWeight="bold">
            <h4>Congratulations!</h4>
          </Text>
          <Text textAlign="center" variant="subtitle1" fontWeight="normal">
            <h4>{`คุณได้รับสิทธิพิเศษจากร้าน ${brandName}`}</h4>
          </Text>
          <CouponCard
            title={reward.title}
            image={reward.image}
            width={258}
            expiredDate={expiredDate}
            markAsUsed={markAsUsed}
          />
          <Text
            textAlign="center"
            variant="subtitle1"
            fontWeight="normal"
            mb={0}
            mt={10}
          >
            <h5>{`*สามารถใช้สิทธิได้ที่ร้าน ${brandName} ทุกสาขา`}</h5>
          </Text>
        </Block>
        <Image
          src={Background}
          width={[300, 400]}
          position={"absolute"}
          bottom={["-6%", "-31%"]}
          left={["3%", "2%"]}
        />
      </Block>
    </Dialog>
  )
}

WelcomeModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
WelcomeModal.defaultProps = {
  open: true,
  loading: false,
  header: false,
  onClose: () => {},
  name: "",
}

export default WelcomeModal

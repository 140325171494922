import React from "react"
import { Image, Text, Block } from "components/atoms"
import redeemedCard from "assets/redeemed-coupon.png"
import couponCard from "assets/coupon.png"
import moment from "moment"
import { jsx } from "@emotion/core"

const CouponsCard = ({
                       expiredDate,
                       image,
                       title,
                       width = null,
                       markAsUsed,
                     }) => {
  const renderCouponText = () => {
    if (moment().isAfter(moment(expiredDate))) {
      return (
        <Text fontWeight={"bold"} color="#d72300">
          Expired
        </Text>
      )
    } else if (markAsUsed) {
      return (
        <Text fontWeight={"bold"} color="#3c7600">
          Redeemed
        </Text>
      )
    } else return <Text fontWeight={"bold"}>Redeem</Text>
  }

  return (
    <Block.Flex
      className={"couponCard"}
      display={"flex"}
      flexDirection={"column"}
      backgroundImage={`url(${markAsUsed ? redeemedCard : couponCard})`}
      width={width || ["100%", 320, 360]}
      backgroundSize={["cover", "contain"]}
      backgroundRepeat={"round"}
      style={{
        borderRadius: "3%",
      }}
    >
      <Block display={"flex"} flexDirection={"row"}>
        <Image
          src={image}
          objectFit={"cover"}
          height={[50, 75]}
          width={["20%", 75]}
          m={["5.5% 3% 3% 9%", "2% 3% 3% 9%"]}
        />
        <Block
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          mt={2}
          mb={2}
        >
          <Block
            css={{
              WebkitLineClamp: "2",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            <Text
              fontWeight={"bold"}
              style={{ wordWrap: "break-word" }}
              variant={"subtitle1"}
            >
              <h2>{title}</h2>
            </Text>
          </Block>
          <Text
            color={"grey"}
            style={{ wordWrap: "break-word" }}
            variant={"subtitle1"}
          >
            <h6>Available until : {moment(expiredDate).format("DD MMM YYYY")}</h6>
          </Text>
        </Block>
      </Block>
      <Block display={"flex"} justifyContent={"center"} mt={"11px"}>
        {renderCouponText()}
      </Block>
    </Block.Flex>
  )
}

export default CouponsCard

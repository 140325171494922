import React from "react"
import get from "lodash/get"
import { Block, Text, Image } from "components/atoms"

// Redux
import { connect } from "react-redux"
import { selectBrands } from "modules/brand/selectors"

const CoverCard = ({ point, image, brandBackend }) => {
  return (
    <Block position={"relative"} width={"100%"} borderRadius={"12px"}>
      <Image
        src={get(brandBackend, "cover", null) || image}
        height={[180, 250]}
        width={"100%"}
        objectFit={"cover"}
        borderRadius={"12px"}
        boxShadow={"0 2px 20px 0 rgba(194, 194, 194, 0.13)"}
      />
      <Block
        p={12}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={"0px 0px 12px 12px"}
        boxShadow={"0 4px 30px 0 rgba(194,194,194,0.13)"}
        background={"white"}
        position={"absolute"}
        left={0}
        right={0}
        bottom={0}
      >
        <Text>Total point</Text>
        <Text fontWeight={"bold"}>{point} Point</Text>
      </Block>
    </Block>
  )
}

export default connect(
  state => ({
    brandBackend: selectBrands(state),
  }),
  null
)(CoverCard)

import React from "react"
import { GatsbyImage, Block } from "components/atoms"

const SocialMedia = ({ socialMedia, margin }) => {
  return socialMedia.map(({ Logo, Action, Name }) => (
    <Block m={margin || "2.5px"} key={Name}>
      <a href={Action} target="_blank">
        <GatsbyImage
          width={40}
          height={40}
          image={Logo.childImageSharp.fluid}
          imageStyle={{ objectFit: "contain" }}
        />
      </a>
    </Block>
  ))
}

export default SocialMedia

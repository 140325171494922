import React from "react"
import { Block, Text, Button } from "components/atoms"
import { Link } from "gatsby"
import { Link as Scroll } from "react-scroll"
import { css } from "@emotion/core"
import ReactMarkdown from "react-markdown"
import withIntl from "hocs/withIntl"
import get from "lodash/get"
import { useSpring, animated } from 'react-spring'


const Article = ({ article, TextColor, TextAlign = null, locale }) => {


  const {
    // Title_th,
    // Title_en,
    // Subtitle_th,
    // Subtitle_en,
    // buttonText_th,
    AdditionalOption,
    buttonText_en,
    Action,
    Width,
    Width_Mobile,
  } = article
  const title = get(AdditionalOption, "title", null)
  const subtitle = get(AdditionalOption, "subtitle", null)
  const animation = get(AdditionalOption, "animation", null)

  const renderButton = () => {
    if (Action) {
      const externalPaths = ["http", "mailto", "tel"]
      const external = externalPaths.some(externalPath =>
        Action.startsWith(externalPath)
      )
      if (external) {
        return (
          <a
            href={Action}
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
          >
            <Button.Contain>{article[`buttonText_${locale}`]}</Button.Contain>
          </a>
        )
      }
      if (Action.startsWith("#")) {
        return (
          <Block
            css={css`
              cursor: pointer;
            `}
          >
            <Scroll to={Action.slice(1)} smooth={true} duration={500} offset={-50}>
              <Button.Contain>{article[`buttonText_${locale}`]}</Button.Contain>
            </Scroll>
          </Block>
        )
      }
      return (
        <Link to={Action} style={{ textDecoration: "none" }}>
          <Button.Contain>{article[`buttonText_${locale}`]}</Button.Contain>
        </Link>
      )
    }
    return (
      <Button.Contain
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {article[`buttonText_${locale}`]}
      </Button.Contain>
    )
  }

  const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: get(animation, 'delay'), config: { duration: get(animation, 'duration') } })

  const renderArticle = () => <Block width={[Width_Mobile || Width || "auto", Width || "auto"]}>
    <Text color={TextColor} fontSize={get(title, 'fontSize')} component={"span"}>
      <ReactMarkdown source={article[`Title_${locale}`]} />
    </Text>
    <Text color={TextColor} variant={"subtitle1"} component={"span"} fontSize={get(subtitle, 'fontSize')}
      fontWeight={get(subtitle, 'fontWeight')}>
      <ReactMarkdown source={article[`Subtitle_${locale}`]} />
    </Text>
    {buttonText_en && (
      <Block mb={"1.5em"}>{buttonText_en && renderButton()}</Block>
    )}
  </Block>


  return get(AdditionalOption, "animation", null)
    ? <animated.h1 style={props}>{renderArticle()}</animated.h1>
    : renderArticle()

}

export default withIntl()(Article)

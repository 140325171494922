import React from "react"
import Img from "gatsby-image"
import { Block } from "components/atoms"
import omit from "lodash/omit"

const GatsbyImage = props => (
  <Block {...omit(props, ["image", "imageStyle"])}>
    <Img
      alt={props.alt}
      fluid={props.image}
      style={{ width: "100%", height: "100%" }}
      imgStyle={{ objectFit: "contain", ...props.imageStyle }}
    />
  </Block>
)

export default GatsbyImage

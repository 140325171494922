import React from "react"
import {
  Block,
  GatsbyImage,
  GatsbyImageBackground,
  Text,
} from "components/atoms"
import { StaticQuery, graphql } from "gatsby"
import { Menu, SocialMedia } from "components/molecules"
import { css } from "@emotion/core"

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          strapiFooter(Name: { eq: "Footer" }) {
            Logo {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            TextColor
            Background {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            BackgroundColor
            Layout
            menus {
              Name
            }
            socialmedias {
              Name
              Logo {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
              Action
            }
          }
        }
      `}
      render={data => {
        const {
          Logo,
          Background,
          BackgroundColor,
          Layout,
          menus,
          TextColor,
          socialmedias,
        } = data.strapiFooter
        return (
          <GatsbyImageBackground
            background={Background && Background.childImageSharp.fluid}
            backgroundColor={BackgroundColor}
          >
            <Block.Flex
              flexDirection={[
                Layout === "left" ? "row" : "column-reverse",
                null,
                Layout === "left" ? "row" : "row-reverse",
              ]}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              alignItems={["flex-start", "center"]}
              maxWidth={["initial", 700, 960, 1200]}
              mx={"auto"}
              p={10}
              borderBottom={"solid 1px #858585"}
            >
              <Block.Flex
                flexDirection={["row", null, "column"]}
                justifyContent={"flex-start"}
                alignSelf={"stretch"}
                alignItems={[
                  "flex-start",
                  null,
                  Layout === "left" ? "flex-start" : "flex-end",
                ]}
                maxWidth={["100%", null, "15%"]}
                minWidth={120}
                p={10}
              >
                <GatsbyImage
                  image={Logo.childImageSharp.fluid}
                  width={90}
                  height={150}
                  imageStyle={{ objectFit: "contain" }}
                  mb={[0, null, "1.5em"]}
                  onClick={() => console.log("clicked")}
                  css={css`cursor: pointer}`}
                />
                <Block width={'40%'} p={10} my={"6px"} display={['block', 'none']} ml={'20%'}>
                  <Text variant={"subtitle1"} mb={[20]}>
                    Subscribe
                  </Text>
                  <Block.Flex
                    flexWrap={"wrap"}
                    justifyContent={'flex-start'}
                    alignItems={"center"}
                  >
                    <SocialMedia socialMedia={socialmedias} />
                  </Block.Flex>
                </Block>
              </Block.Flex>
              <Block.Flex
                flexDirection={["row"]}
                flexWrap={"wrap"}
                width={["100%", null, "80%"]}
                py={10}
              // css={css`word-break: break-word}`}
              >
                {menus.map(({ Name }) => (
                  <Menu menuName={Name} TextColor={TextColor} key={Name} />
                ))}
                <Block width={'20%'} p={10} my={"6px"} display={['none', 'block']}>
                  <Text variant={"subtitle1"} my={[10]}>
                    Subscribe
                  </Text>
                  <Block.Flex
                    flexWrap={"wrap"}
                    justifyContent={'flex-start'}
                    alignItems={"center"}
                  >
                    <SocialMedia socialMedia={socialmedias} />
                  </Block.Flex>
                </Block>
              </Block.Flex>
            </Block.Flex>
            <Text textAlign={"center"} py={10}>
              Copyright © 2019 Pumpkinapp. All rights reserved.
            </Text>
          </GatsbyImageBackground>
        )
      }}
    />
  )
}

export default Footer

import React from "react"
import { css } from "@emotion/core"
import { Block, GatsbyImageBackground } from "components/atoms"
import {
  Article,
  Promotion,
  BranchesSection,
  RecommendedProduct,
} from "components/molecules"
import {
  MediaSection,
  CardAndReward,
  Slider,
  CategoryList,
} from "components/organisms"
import get from "lodash/get"
import { Link } from "gatsby"
import { Link as Scroll } from "react-scroll"
import { StaticQuery, graphql } from "gatsby"
import { split } from "lodash"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const Section = ({ sectionName, height, brand, slider }) => {
  return (
    <StaticQuery
      query={graphql`
        query SectionAndContentQuery {
          allStrapiSection {
            nodes {
              Name
              flexFlow
              justifyContent
              alignItems
              Background {
                childImageSharp {
                  fluid(maxWidth: 4160) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              Background_Mobile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              BackgroundColor
              Action
              contents {
                Name
                TextAlign
              }
              Optional
              promotion {
                Name
              }
              categorylist {
                Name
              }
              fullWidth
              Spacing
              AdditionalOption {
                padding
                storeText {
                  description
                  header
                }
              }
            }
          }

          allStrapiContent {
            nodes {
              Name
              TextColor
              TextAlign
              Width
              Width_Mobile
              Height
              Height_Mobile
              AlignItems
              articles {
                Name
                Title_en
                Title_th
                Subtitle_en
                Subtitle_th
                buttonText_en
                buttonText_th
                Action
                Width
                Width_Mobile
                AdditionalOption {
                  animation {
                    delay
                    duration
                  }
                  subtitle {
                    fontSize
                    fontWeight
                  }
                  title {
                    fontSize
                  }
                }
              }
              media {
                Media {
                  ext
                  internal {
                    description
                  }
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                Name
                Action
                VideoUrl
                Height
                Height_Mobile
                Width
                Width_Mobile
              }
              sliders {
                Name
              }
              Background {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              BackgroundColor
              product {
                Name_en
              }
            }
          }
        }
      `}
      render={data => {
        const matches = useMediaQuery("(min-width:640px)")
        const section = data.allStrapiSection.nodes.find(
          ({ Name }) => Name === sectionName
        )
        const {
          Name,
          flexFlow,
          justifyContent,
          alignItems,
          Background,
          Background_Mobile,
          BackgroundColor,
          Action,
          contents,
          Optional,
          promotion,
          categorylist,
          fullWidth,
          Spacing,
          AdditionalOption,
        } = section
        const contentRenderer = () => {
          const flexWrap = split(flexFlow, " ")[1]
          return (
            <Block.Flex
              css={
                flexWrap
                  ? css`
                      flex-flow: ${flexFlow};
                    `
                  : css`
                      flex-flow: ${flexFlow} wrap;
                    `
              }
              justifyContent={[justifyContent || "center", justifyContent]}
              alignItems={[alignItems || "center", alignItems]}
            >
              {contents.map(({ Name }) => {
                const content = data.allStrapiContent.nodes.find(
                  node => node.Name === Name
                )
                return (
                  <GatsbyImageBackground
                    background={
                      content.Background &&
                      content.Background.childImageSharp.fluid
                    }
                    backgroundImage={content.BackgroundColor}
                    backgroundColor={content.BackgroundColor || "transparent"}
                    width={[
                      content.Width_Mobile || "90%",
                      content.Width || "45%",
                    ]}
                    height={[
                      content.Height_Mobile || "auto",
                      content.Height || "auto",
                    ]}
                    m={[Spacing]}
                    key={content.Name}
                  >
                    <Block
                      textAlign={["center", content.TextAlign || "center"]}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={content.AlignItems || "center"}
                      pt={[
                        content.AlignItems === "flex-start" ? "4.75%" : 0,
                        content.AlignItems === "flex-start" ? "10%" : 0,
                      ]}
                      height={"inherit"}
                    >
                      {content.product && (
                        <RecommendedProduct
                          productName={content.product.Name_en}
                        />
                      )}
                      {content.sliders && content.sliders.length > 0 && (
                        <Slider
                          Name={content.sliders[0].Name}
                          height={[content.Height_Mobile, content.Height]}
                          contentName={content.Name}
                        />
                      )}
                      {!!content.media.length && (
                        <Block.Flex
                          flexWrap={"wrap"}
                          mb={!!content.articles.length && "1.5em"}
                          justifyContent={[
                            justifyContent || "center",
                            justifyContent,
                          ]}
                          alignItems={[alignItems || "center", alignItems]}
                          width={"100%"}
                        >
                          {content.media.map(media => (
                            <MediaSection media={media} key={media.Name} />
                          ))}
                        </Block.Flex>
                      )}
                      {content.articles.map(article => (
                        <Article
                          article={article}
                          TextColor={content.TextColor}
                          key={article.Name}
                        />
                      ))}
                    </Block>
                  </GatsbyImageBackground>
                )
              })}
            </Block.Flex>
          )
        }
        const sectionRenderer = () => (
          <GatsbyImageBackground
            id={Name}
            height={height}
            background={
              matches
                ? Background && Background.childImageSharp.fluid
                : (Background_Mobile &&
                    Background_Mobile.childImageSharp.fluid) ||
                  (Background && Background.childImageSharp.fluid)
            }
            backgroundImage={BackgroundColor}
            backgroundColor={BackgroundColor}
            py={slider || get(AdditionalOption, "padding") || [30]}
          >
            <Block
              maxWidth={fullWidth ? null : ["initial", 700, 960, 1200]}
              width={fullWidth || categorylist ? "100%" : ["100%", "90vw"]}
              mx={"auto"}
            >
              {brand &&
                (() => {
                  switch (Optional) {
                    case "Card And Rewards":
                      return <CardAndReward brand={brand} />
                    case "Branches":
                      return (
                        <BranchesSection
                          branches={brand.branches}
                          storeText={get(AdditionalOption, "storeText")}
                        />
                      )
                  }
                })()}
              {categorylist && <CategoryList Name={categorylist.Name} />}
              {promotion && <Promotion Name={promotion.Name} />}

              {contents.length > 0 && contentRenderer()}
            </Block>
          </GatsbyImageBackground>
        )
        if (Action) {
          const externalPaths = ["http", "mailto", "tel"]
          const external = externalPaths.some(externalPath =>
            Action.startsWith(externalPath)
          )
          if (external) {
            return (
              <a
                href={Action}
                style={{ textDecoration: "none" }}
                rel="noopener noreferrer"
              >
                {sectionRenderer()}
              </a>
            )
          }
          if (Action.startsWith("#")) {
            return (
              <Block
                css={css`
                  cursor: pointer;
                `}
              >
                <Scroll
                  to={Action.slice(1)}
                  smooth={true}
                  duration={500}
                  offset={-50}
                >
                  {sectionRenderer()}
                </Scroll>
              </Block>
            )
          }
          return (
            <Link to={Action} style={{ textDecoration: "none" }}>
              {sectionRenderer()}
            </Link>
          )
        }
        return sectionRenderer()
      }}
    />
  )
}

export default Section

import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { ClassNames } from "@emotion/core"
import { css } from "@emotion/core"

// COMPONENTS
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { Block, Text, Image } from "components/atoms"

// ICONS
import { Close } from "@material-ui/icons"

// REDUX

// HELPERS

const Modal = styled(
  ({
     className,
     open,
     onClose,
     logo,
     color,
     title,
     content,
     actions,
     fullScreen,
     noHeader,
   }) => {
    return (
      <ClassNames>
        {({ cx }) => (
          <Dialog
            classes={{ paper: "paper" }}
            open={open}
            onClose={onClose}
            className={cx("modal", className)}
            fullScreen={fullScreen}
          >
            {noHeader ? (
              <Block.Flex mb={55} justifyContent="flex-end">
                <div className={"close-icon"}>
                  <Close onClick={onClose} />
                </div>
              </Block.Flex>
            ) : (
              <Block.Flex
                justifyContent={"space-between"}
                height={120}
                backgroundColor={color}
                css={css`
                  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
                `}
              >
                <Block pl={16} py={16}>
                  <Text fontSize={4}>{title}</Text>
                </Block>
                <div className={"close-icon"}>
                  <Close onClick={onClose} />
                </div>
              </Block.Flex>
            )}
            <DialogContent className={"dialog-content"}>
              {logo && (
                <Block
                  width={90}
                  height={90}
                  borderRadius={"50%"}
                  boxShadow={"2px 2px 4px 0 rgba(0, 0, 0, 0.1)"}
                  objectFit={"cover"}
                  backgroundColor={"white"}
                  display={"flex"}
                  m={"auto"}
                >
                  <Image src={logo} width={80} height={80} margin={'auto'}/>
                </Block>
              )}
              {content}
            </DialogContent>
            <DialogActions className={"actions"}>
              {React.Children.toArray(actions)}
            </DialogActions>
          </Dialog>
        )}
      </ClassNames>
    )
  },
)(({ color, minWidth = 300, maxWidth = 1000 }) => ({
  ".app-bar": {
    position: "relative",
    marginBottom: 16,
    ".app-bar-title": {
      flex: 1,
    },
  },
  ".paper": {
    minWidth: minWidth,
    maxWidth: maxWidth,
    borderRadius: 16,
  },
  ".dialog-content": {
    marginTop: "-55px",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  ".actions": {
    margin: "0 16px 16px",
    ".button:last-child": {
      marginRight: 0,
    },
  },
  ".close-icon": {
    margin: "16px 16px 0 0",
    svg: {
      borderRadius: "50%",
      transition: "0.3s",
      backgroundColor: "white",
      color: color,
      fontSize: "32px",
      cursor: "pointer",
      padding: "5px",
    },
    // ':hover': {
    //   svg: {
    //     color: theme.get('colors.black')
    //   }
    // }
  },
}))

Modal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
Modal.defaultProps = {
  open: false,
  loading: false,
  logo: null,
  onClose: () => {
  },
  name: "",
}

export default Modal

import React from "react"
import { navigate } from "gatsby"
import { Block, CoverCard, Text, Button } from "components/atoms"
import { CardReward } from "components/molecules"
import get from "lodash/get"

// REDUX
import { connect } from "react-redux"
import { selectAuthData } from "modules/auth/selectors"

const CardAndReward = ({ brand, profile }) => {
  const userCards = get(profile, "cards", [])
  const brandInUerCard = userCards.find(card => card.brand.id === brand.id)
  const point = get(brandInUerCard, "point", 0)
  return (
    <Block
      pt={[15, 30]}
      textAlign={"center"}
      css={{
        ".MuiButton-containedPrimary": {
          textTransform: "inherit",
          width: 190,
        },
        ".MuiButton-label": {
          fontSize: "1.2rem",
          fontWeight: "bold",
        },
      }}
    >
      <Text mt={[10, 0]}>
        <h2>Special Reward for you</h2>
      </Text>
      <Block.Flex
        my={15}
        width={"100%"}
        justifyContent={"center"}
        flexDirection={["column", null, "row"]}
        alignItems={["center", null, "flex-start"]}
      >
        <Block mt={[0, 24]} mx={"5%"} mb={[24, null, 0]}>
          <Block width={["auto", 450]}>
            <CoverCard point={point} image={brand.cover} />
          </Block>
        </Block>
        <Block display={"block"} width={"100%"} maxWidth={470}>
          <CardReward brand={brand} brandId={brand.id} />
        </Block>
      </Block.Flex>
      {!profile && (
        <Button.Contain
          rounded
          mt={[10, 25]}
          TextTransform={"inherit"}
          onClick={() => navigate("/login")}
        >
          Register
        </Button.Contain>
      )}
    </Block>
  )
}

const mapStateToProps = state => ({
  profile: selectAuthData(state),
})

export default connect(
  mapStateToProps,
  null
)(CardAndReward)

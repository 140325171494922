import React from 'react';
import styled from '@emotion/styled';
import { ClassNames } from '@emotion/core';
import { compose, typography, space, color, flexbox,layout ,grid ,background,border,position,shadow} from 'styled-system'

const name = 'pumpkin-block';
const BaseBlock = styled('div')(({ css }) => css);
// create base block to whitelist all styles attr that will be passed to HtmlTag
const Block = styled(
  ({ className, ...props }) => (
    <ClassNames>
      {({ cx }) => (
        <BaseBlock className={cx(name, className)} {...props} />
      )}
    </ClassNames>
  )
)( compose(
  typography,
  space,
  color,
  flexbox,
  layout,
  grid,
  background,
  border,
  position,
  shadow
));

const FullScreenCenter = (props) => (
  <Block
    width={'100vw'}
    height={'100vh'}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    {...props}
  />
);
const Flex = (props) => (
  <Block
    display={'flex'}
    {...props}
  />
);

const Center = (props) => (
  <Block
    display={'flex'}
    justifyContent={'center'}
    {...props}
  />
);

const Middle = (props) => (
  <Block
    display={'flex'}
    alignItems={'center'}
    {...props}
  />
);

const MiddleCenter = (props) => (
  <Block
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    {...props}
  />
);

Block.FullScreenCenter = FullScreenCenter;
Block.Center = Center;
Block.Middle = Middle;
Block.MiddleCenter = MiddleCenter;
Block.Flex = Flex;

export default Block;

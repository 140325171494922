import React from 'react';
import moment from 'moment';

//COMPONENTS
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { Text, Image } from 'components/atoms';

// ICONS
import appstore from 'assets/app-store.png';
import googleplay from 'assets/google-play.png';

// IMAGE
import pumpkinReward from 'assets/pumpkin-gift.png';

const styles = theme => {
  return {
    container: {
      textAlign: '-webkit-center'
    },
    pumpkinGiftImage: {
      width: 135,
      height: 'auto',
      marginBottom: 10
    },
    divider: {
      borderTopColor: '#e4e4e4',
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      width: '40%'
    },
    downloadLink: {
      paddingTop: 10,
      paddingBottom: 7
    },
    rewardImage: {
      marginTop: 7,
      marginBottom: 7,
      width: 80,
      borderRadius: 8
    },
    indentSpace: {
      marginTop: 20
    },
    pumpkinImage: {
      height: 200,
      transform: 'scale(0.8)',
      transition: 'all 0.3s ease-in-out'
    },
    pumpkinImageScale: {
      transform: 'scale(1.1)'
    }
  };
};

class SurveysReward extends React.Component {
  state = {
    imageScale: false
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ imageScale: true });
    }, 500);
  }
  getMonth = month => {
    switch (month) {
      case '01':
        return 'ม.ค.';
      case '02':
        return 'ก.พ.';
      case '03':
        return 'มี.ค.';
      case '04':
        return 'เม.ย.';
      case '05':
        return 'พ.ค.';
      case '06':
        return 'มิ.ย.';
      case '07':
        return 'ก.ค.';
      case '08':
        return 'ส.ค.';
      case '09':
        return 'ก.ย.';
      case '10':
        return 'ต.ค.';
      case '11':
        return 'พ.ย';
      case '12':
        return 'ธ.ค.';
      default:
        return '';
    }
  };

  getReadableDateFromDatetime = date => {
    const momentDate = moment(date);
    return `${momentDate.format('DD')} ${this.getMonth(
      momentDate.format('MM')
    )} ${momentDate.format('YYYY')} เวลา ${momentDate.format(
      'HH'
    )}:${momentDate.format('mm')}`;
  };
  render() {
    const { classes, reward, brandName, getReward, expiredDate } = this.props;
    const { imageScale } = this.state;
    return (
      <div className={classes.container} >
        {getReward && reward ? (
          <div style={{marginBottom:20 , marginTop:20 ,}}>
            <Image
              src={pumpkinReward}
              className={
                imageScale
                  ? `${classes.pumpkinImage} ${classes.pumpkinImageScale}`
                  : `${classes.pumpkinImage}`
              }
              style={{margin:30 ,}}
            />
            <Text fontSize={['1.25rem','1.5rem']} fontWeight={'bold'} fontFamily={'thai-sans-neue'}>
              ยินดีด้วย! คุณได้รับ
            </Text>
            <Text fontSize={['1.25rem','1.5rem']} fontWeight={'bold'} fontFamily={'thai-sans-neue'}>
              รางวัล {reward.title}
            </Text>
            <Image src={reward.image} className={classes.rewardImage} style={{margin:30}}/>
            <Text fontSize={'1rem'} fontFamily={'thai-sans-neue'}>โปรดแจ้งพนักงานที่หน้าร้าน</Text>
            <Text fontSize={'1rem'} fontFamily={'thai-sans-neue'}>เพื่อรับของรางวัล</Text>
            <Text fontSize={['1.25rem','1.5rem']} fontWeight={'bold'} style={{margin:10}} fontFamily={'thai-sans-neue'}>
              หมดอายุ {this.getReadableDateFromDatetime(expiredDate)}
            </Text>
          </div>
        ) : (
          <div>
            <Text fontSize={'1.5rem'} fontWeight={'bold'} fontFamily={'thai-sans-neue'}>
              ร้าน {brandName}
            </Text>
            <Text fontFamily={'thai-sans-neue'}>ขอขอบคุณลูกค้า</Text>
            <Text fontFamily={'thai-sans-neue'}>ที่กรุณาทำแบบสอบถาม</Text>
          </div>
        )}
        <Text fontSize={'1.25rem'} className={classes.indentSpace} style={{marginTop:40 , marginBottom:10 }} fontFamily={'thai-sans-neue'}>
          ดาวน์โหลด Application
        </Text>
        <Grid container spacing={24} className={classes.downloadLink} style={{display:'flex' ,justifyContent:'center',}}>
          <Grid item xs={6} md={4}>
            <a href="itms://itunes.apple.com/th/app/pumpkin/id1392855143?mt=8" >
              <Image src={appstore} style={{marginBottom:20 ,}}/>
            </a>
          </Grid>
          <Grid item xs={6} md={4}>
            <a href="market://details?id=com.mi.pumpkin" >
              <Image src={googleplay} style={{marginBottom:20,}}/>
            </a>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SurveysReward);

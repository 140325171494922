/** @jsx jsx */
import React from "react"
import { Block, Text } from "components/atoms"
import { Slick, ProductCard, LandscapeProductCard } from "components/molecules"
import { StaticQuery, graphql } from "gatsby"
import { jsx } from "@emotion/core"
import withIntl from "hocs/withIntl"
import useMediaQuery from "@material-ui/core/useMediaQuery"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

// Lodash
import get from "lodash/get"

const CategoryList = ({ Name, locale, theme, messages }) => {
  const [activeCategory, setActiveCategory] = React.useState(0)
  const [slidesToShow, setSlideToShow] = React.useState(0)
  const [getSlickWidth, setGetSlickWidth] = React.useState(0)
  const matches = useMediaQuery("(min-width:640px)")
  const primary = get(theme, "colors.primary")
  const darkGrey = get(theme, "colors.darkGrey")
  const productCardStyle = get(theme, "productCard")
  return (
    <StaticQuery
      query={graphql`
        query CategoryListQuery {
          allStrapiCategorylist {
            nodes {
              Name
              id
              SlideToShow
              categories {
                id
                Name
                products
              }
              ProductCardType
            }
          }
          allStrapiCategory {
            nodes {
              Name
              Title_en
              Title_th
              Description_en
              Description_th
              id
              products {
                Price
                Name_en
                Name_th
                Description_en
                Description_th
                Image {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const categoryList = data.allStrapiCategorylist.nodes.find(
          categoryList => categoryList.Name === Name
        )
        const { ProductCardType } = categoryList
        const categories = categoryList.categories.map(category => {
          return data.allStrapiCategory.nodes.find(
            categoryData => categoryData.Name === category.Name
          )
        })

        const checkSlideToShow = toRender => {
          return toRender > categories[activeCategory].products.length
            ? categories[activeCategory].products.length
            : toRender > 0
            ? toRender
            : 1
        }

        return (
          <Block>
            <Block
              display={["flex", "flex"]}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={"column"}
              p={[0, 24]}
            >
              <Text mb={[20, 0]} variant={"h3"}>
                {get(messages, "OurMenu")}
              </Text>
              <Block display={"flex"} width={["100%", "auto"]}>
                {categories.map((category, index) => (
                  <Block
                    mr={[0, 35]}
                    textAlign={["center", "left"]}
                    width={[`${100 / categories.length}%`, "auto"]}
                  >
                    <Text
                      onClick={() => setActiveCategory(index)}
                      variant={"h5"}
                      style={{
                        cursor: "pointer",
                        color: activeCategory === index && primary,
                        borderBottom:
                          activeCategory === index && `1px solid ${primary}`,
                      }}
                    >
                      {category[`Title_${locale}`]}
                    </Text>
                  </Block>
                ))}
              </Block>
              <Block px={[20, 0]} display={"flex"} my={"20px"}>
                <Text variant={"subtitle1"}>
                  {categories[activeCategory][`Description_${locale}`]}
                </Text>
              </Block>
            </Block>
            <Slick
              settings={{
                autoplay: false,
                infinite: true,
                slidesToShow: checkSlideToShow(slidesToShow),
                slidesToScroll: checkSlideToShow(slidesToShow),
              }}
              dotsBottomPosition={-42}
              pb={[30, 50]}
              px={[0, 80]}
              leftArrowPos={-65}
              rightArrowPos={-65}
              ArrowMobilePos={6}
              setGetSlickWidth={setGetSlickWidth}
              dotsColor={darkGrey}
              arrowColor={primary}
              verticalArrowPos={[114, 203]}
              boxShadow={"0px 1px 5px 3px rgba(78, 77, 77, 0.14)"}
            >
              {(() => {
                switch (ProductCardType) {
                  case "Portrait Image":
                    return categories[activeCategory].products.map(product => (
                      <ProductCard
                        product={product}
                        productCardStyle={productCardStyle}
                      />
                    ))
                  case "Landscape Image":
                    matches
                      ? setSlideToShow(Math.floor(getSlickWidth / 280))
                      : setSlideToShow(Math.floor(getSlickWidth / 160))
                    return categories[activeCategory].products.map(product => (
                      <LandscapeProductCard
                        product={product}
                        productCardStyle={productCardStyle}
                        mobileWidth={(getSlickWidth * 42.5) / 100}
                      />
                    ))
                }
              })()}
            </Slick>
          </Block>
        )
      }}
    />
  )
}

export default withIntl()(withEmotion(CategoryList))

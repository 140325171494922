import { createMuiTheme } from "@material-ui/core/styles"
import baseTheme from "./baseTheme"

const muiBaseTheme = createMuiTheme()

const theme = createMuiTheme({
  palette: {
    primary: baseTheme.palette.primary,
  },
  typography: {
    fontFamily: "Db-Ozone-X",
    caption: {
      fontSize: 16,
    },
    overline: {
      fontSize: 16,
    },
    body1: {
      fontSize: 18,
      "&>h1": {
        margin: 0,
        fontSize: "3em",
      },
      "&>h2": {
        margin: 0,
        fontSize: "2.25em",
      },
      "&>h3": {
        margin: 0,
        fontSize: "2em",
      },
      "&>h4": {
        margin: 0,
        fontFamily: ["Arsenale-White-trial", "Db-Ozone-X"],
        fontWeight: 400,
        color: "#f7d85c",
        fontSize: "4em",
        lineHeight: ".5em",
      },
      "&>h5": {
        margin: 0,
        fontWeight: 400,
        fontFamily: ["Arsenale-White-trial", "Db-Ozone-X"],
        marginBottom: "0.5em",
        fontSize: "4em",
        lineHeight: ".5em",
      },
      "&>h6": {
        margin: 0,
        fontSize: "1.2em",
      },
    },
    subtitle1: {
      fontSize: 18,
      lineHeight: "1.5em",
      "&>h1": {
        margin: 0,
        fontSize: "1.115em",
      },
      "&>h2": {
        margin: 0,
        fontWeight: "inherit",
        fontSize: "1.115em",
      },
      "&>h3": {
        fontSize: "1.25rem",
      },
      "&>h4": {
        fontSize: "1.15rem",
      },
      "&>h5": {
        fontSize: "0.95rem",
      },
      "&>h6": {
        margin: 0,
        fontWeight: "inherit",
        fontSize: "1em",
        lineHeight: "1em",
        color: "#b5b5b5",
      },
    },
    button: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    letterSpacing: 1,
  },
  overrides: {
    MuiTypography: {
      root: {
        color: "black",
        "&>*": {
          marginBottom: "1em",
        },
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: 0,
      },
    },
    MuiIconButton: {
      root: {
        "&.icon-button--small": {
          padding: 8,
        },
      },
    },
    MuiBottomNavigation: {
      root: {
        height: 60,
        boxShadow: "none",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        "&$selected": {
          paddingTop: 8,
        },
      },
      label: {
        letterSpacing: 3,
        "&$selected": {
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      label: {
        letterSpacing: "1.5px",
      },
      contained: {
        boxShadow: "none",
      },
    },
    MuiAppBar: {
      root: {
        "&.MuiPaper-root": {
          backgroundColor: baseTheme.get("colors.white"),
          boxShadow: "0 0 10px 0 rgba(0,0,0,0.12)",
        },
      },
    },
    MuiDrawer: {
      paper: {
        [muiBaseTheme.breakpoints.down("xs")]: {
          minWidth: 200,
        },
      },
    },
    MuiCard: {
      root: {
        border: "1px solid rgba(0,0,0,.125)",
        boxShadow: "0 5px 20px 0 rgba(0,0,0,0.1)",
        borderRadius: 8,
      },
    },
  },
})

export const overridesTheme = {
  MuiTypography: {
    root: {
      color: "black",
      "&>*": {
        marginBottom: "0.5em",
      },
    },
  },
  MuiListItemIcon: {
    root: {
      marginRight: 0,
    },
  },
  MuiIconButton: {
    root: {
      "&.icon-button--small": {
        padding: 8,
      },
    },
  },
  MuiBottomNavigation: {
    root: {
      height: 60,
      boxShadow: "none",
    },
  },
  MuiBottomNavigationAction: {
    root: {
      "&$selected": {
        paddingTop: 8,
      },
    },
    label: {
      letterSpacing: 3,
      "&$selected": {
        fontSize: 14,
      },
    },
  },
  MuiButton: {
    label: {
      letterSpacing: "1.5px",
      fontWeight: "bold",
    },
    contained: {
      boxShadow: "none",
    },
  },
  MuiAppBar: {
    root: {
      "&.MuiPaper-root": {
        backgroundColor: baseTheme.get("colors.white"),
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.12)",
      },
    },
  },
  MuiDrawer: {
    paper: {
      [muiBaseTheme.breakpoints.down("xs")]: {
        minWidth: 200,
      },
    },
  },
  MuiCard: {
    root: {
      border: "1px solid rgba(0,0,0,.125)",
      boxShadow: "0 5px 20px 0 rgba(0,0,0,0.1)",
      borderRadius: 8,
    },
  },
}

export default theme

import React from "react"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { Text, Block } from "components/atoms"
import SubMenu from "components/molecules/SubMenu"
import { ArrowBackIos } from "@material-ui/icons"
// import { ProfileMenu } from "components/molecules"

const Navigator = ({
  items,
  open,
  onClose,
  TextColor,
  switchLanguage,
  locale,
  crmOption = "available",
}) => {
  const closeAfter = path => {
    // navigate(path)
    onClose()
  }
  return (
    <Drawer open={open} onClose={onClose}>
      <List>
        <Block
          p={16}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          css={{ ".MuiSvgIcon-root": { fontSize: "2.5rem" } }}
        >
          <ArrowBackIos onClick={() => onClose()} />
          {/* {crmOption === "available" && <ProfileMenu />} */}
        </Block>
        {items.map(({ Text, Action, TextColor }) => (
          // <ListItem button onClick={() => closeAfter()} key={Name}>
          // <Text color={TextColor} variant={"subtitle2"}>
          //   {Text_en}
          // </Text>
          // </ListItem>
          <Block borderBottom={"1px solid #e4e4e4"}>
            <ListItem button key={Text}>
              <SubMenu
                content={Text}
                Action={Action}
                TextColor={TextColor}
                closeAfter={closeAfter}
              />
            </ListItem>
          </Block>
        ))}
        <Block mt={20} display={"flex"} px={15}>
          <Block
            p={10}
            width={"50%"}
            textAlign={"center"}
            border={"1px solid"}
            borderColor={locale === "en" ? "#6a6a6a" : "#dadada"}
            borderRadius={"20px 0px 0px 20px"}
            onClick={() => switchLanguage("en")}
          >
            <Text color={locale === "en" ? "#6a6a6a" : "#dadada"}>English</Text>
          </Block>
          <Block
            p={10}
            width={"50%"}
            textAlign={"center"}
            border={"1px solid"}
            borderColor={locale === "th" ? "#6a6a6a" : "#dadada"}
            borderRadius={"0px 20px 20px 0px"}
            onClick={() => switchLanguage("th")}
          >
            <Text color={locale === "th" ? "#6a6a6a" : "#dadada"}>Thai</Text>
          </Block>
        </Block>
      </List>
    </Drawer>
  )
}

export default Navigator

import Section from "./Section"
import Content from "./Content"
import Navigator from "./Navigator"
import MediaSection from "./MediaSection"
import Footer from "./Footer"
import Slider from "./Slider"
import CardAndReward from "./CardAndReward"
import NotificationSection from "./NotificationSection"
import CategoryList from "./CategoryList"

export {
  Section,
  MediaSection,
  Footer,
  Navigator,
  Slider,
  CardAndReward,
  NotificationSection,
  CategoryList,
  Content,
}

import React from "react"

import { ThemeProvider as EmotionThemeProvider } from "emotion-theming"
import { ThemeProvider } from "@material-ui/styles"
import { StaticQuery, graphql } from "gatsby"
import { overridesTheme } from "../theme/muiTheme"
import { createMuiTheme } from "@material-ui/core/styles"
import mapKeys from "lodash/mapKeys"
import omit from "lodash/omit"
import get from "lodash/get"
import pick from "lodash/pick"

export default () => WrappedComponent => props => (
  <StaticQuery
    query={graphql`
      query ThemeQuery {
        strapiTheme(Name: { eq: "Theme" }) {
          MuiTheme {
            typography {
              body1 {
                fontSize
                _xh1 {
                  margin
                  fontSize
                  lineHeight
                }
                _xh2 {
                  margin
                  fontSize
                  lineHeight
                }
                _xh3 {
                  margin
                  fontSize
                }
                _xh4 {
                  fontSize
                  fontWeight
                  fontFamily
                  margin
                  color
                  lineHeight
                }
                _xh5 {
                  fontSize
                  fontWeight
                  fontFamily
                  margin
                  marginBottom
                  lineHeight
                }
                _xh6 {
                  margin
                  fontSize
                }
              }
              button {
                fontSize
                fontWeight
              }
              caption {
                fontSize
              }
              fontFamily
              letterSpacing
              subtitle1 {
                fontSize
                lineHeight
                _xh1 {
                  margin
                  fontSize
                }
                _xh2 {
                  margin
                  fontWeight
                  fontSize
                }
                _xh3 {
                  fontSize
                }
                _xh4 {
                  fontSize
                }
                _xh5 {
                  fontSize
                }
                _xh6 {
                  margin
                  fontWeight
                  fontSize
                  lineHeight
                  color
                }
              }
              subtitle2 {
                color
                fontSize
                lineHeight
              }
              h3 {
                fontSize
                fontWeight
                margin
                lineHeight
              }
              h4 {
                fontSize
                fontWeight
                margin
                lineHeight
              }
              h5 {
                fontSize
                fontWeight
                margin
                lineHeight
              }
              h6 {
                fontSize
                lineHeight
              }
              overline {
                fontSize
              }
            }
            palette {
              primary {
                contrastText
                dark
                light
                main
              }
            }
            overrides {
              MuiFormLabel {
                root {
                  fontSize
                }
              }
              MuiInputBase {
                input {
                  fontSize
                }
              }
              MuiButton {
                root {
                    borderRadius
                }
              }
            }
          }
          BaseTheme {
            colors {
              black
              danger
              darkGrey
              delete
              extraLightGrey
              grey
              lightGrey
              lightPrimary
              line
              lineDarker
              primary
              secondary
              success
              veryLightGrey
              white
            }
            dashboard {
              boxShadow
              normal {
                borderRadius
                margin
                marginTop
                maxWidth
              }
            }
            fontWeights {
              black
              bold
              extraBold
              extraLight
              light
              regular
              semiBold
              ultraBold
              ultraLight
            }
            globalShadows {
              baseShadow
              lightShadow
            }
            palette {
              delete {
                contrastText
                dark
                light
                main
              }
              primary {
                contrastText
                dark
                light
                main
              }
              secondary {
                contrastText
                dark
                light
                main
              }
              type
            }
            paper {
              header {
                padding
              }
              margin
              marginTop
              maxWidth
              padding
              transition
            }
            productCard {
              nameFontSize
              priceFontSize
            }
            loginPage {
              titleFontSize
            }
            fonts {
              fontFamily
              fontSize
            }
            MyAccount {
              titleFontSize
            }
            CardReward {
              pointFontSize
            }
            MyCard {
              conditionDetailFrontSize
              conditionFrontSize
              pointFrontSize
              yorPointFrontSize
            }
            StoreMap {
              informationFontSize
              locationFontSize
            }
          }
          Logo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          LoginBackGround {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={data => {
      const muiTheme = get(data, "strapiTheme.MuiTheme")
      const baseTheme = get(data, "strapiTheme.BaseTheme")
      const logo = get(data, "strapiTheme.Logo")
      const loginBackground = get(data, "strapiTheme.LoginBackGround")
      const typography = get(muiTheme, "typography")
      const overridesFromTheme = get(muiTheme, "overrides")
      const { fontFamily, fontSize, body1, subtitle1 } = pick(typography, [
        "fontFamily",
        "fontSize",
        "body1",
        "subtitle1",
      ])
      const changeBody1 = mapKeys(omit(body1, ["fontSize"]), (data, key) =>
        key.replace(/_x/gi, "&>")
      )
      const changeSubtitle1 = mapKeys(
        omit(subtitle1, ["fontSize"]),
        (data, key) => key.replace(/_x/gi, "&>")
      )
      return (
        <EmotionThemeProvider theme={baseTheme}>
          <ThemeProvider
            theme={createMuiTheme({
              ...omit(muiTheme, ["overrides"]),
              overrides: {
                ...overridesTheme,
                ...overridesFromTheme,
              },
              typography: {
                ...muiTheme.typography,
                fontFamily,
                fontSize,
                body1: { ...changeBody1, fontSize: body1.fontSize },
                subtitle1: { ...changeSubtitle1, fontSize: subtitle1.fontSize },
              },
            })}
          >
            <WrappedComponent
              {...props}
              logo={logo}
              loginBackground={loginBackground}
            />
          </ThemeProvider>
        </EmotionThemeProvider>
      )
    }}
  />
)

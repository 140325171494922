import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import { Block, Text, Image } from "components/atoms"
import { AccessTime, LocalPhone, CollectionsOutlined } from "@material-ui/icons"

const useStyles = makeStyles({
  card: {
    height: "97%",
    textAlign: "center",
  },
  CardActionArea: {
    height: "100%",
  },
})

const useWindowSize = () => {
  const isClient = typeof window === "object"
  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    }
  }
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}
export default function StoreCard({
  store,
  changeActiveStore,
  activeCard,
  showGalleries,
}) {
  const classes = useStyles()
  const size = useWindowSize()
  return (
    <Block
      width={["100%", (size.height * 35) / 100, 320]}
      mx={"auto"}
      css={{
        ".MuiPaper-root": {
          boxShadow: activeCard ? "0 2px 14px 0 rgba(0, 0, 0, 0.06)" : "none",
          border: "none",
        },
      }}
    >
      <Card className={classes.card} onClick={() => changeActiveStore()}>
        <CardActionArea className={classes.CardActionArea}>
          <Block height={"100%"}>
            <Block position={"relative"}>
              <Image
                height={[(size.height * 19) / 100, 150]}
                width={"100%"}
                objectFit={"cover"}
                src={store.shopImage}
              />
              {store.galleries && (
                <Block
                  position={"absolute"}
                  color={"white"}
                  right={"2.5%"}
                  top={"4.65%"}
                  onClick={() => showGalleries()}
                >
                  <CollectionsOutlined />
                </Block>
              )}
            </Block>
            <CardContent>
              <Text mb={"5px"} variant={"h4"}>
                {store.displayName}
              </Text>
              <Block
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mb={"0.5em"}
              >
                <AccessTime />
                <Text variant={"caption"} ml={"5px"}>
                  {store.openHour}
                </Text>
              </Block>
              <Block
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <LocalPhone />
                <Text variant={"caption"} ml={"5px"}>
                  {store.tel}
                </Text>
              </Block>
            </CardContent>
          </Block>
        </CardActionArea>
      </Card>
    </Block>
  )
}

import React from "react"
import { Block, GatsbyImage, Text } from "components/atoms"
import withIntl from "hocs/withIntl"

// Lodash
import get from "lodash/get"

const ProductCard = ({ product, locale, productCardStyle }) => {
  const nameFontSize = get(productCardStyle, "nameFontSize")
  const priceFontSize = get(productCardStyle, "priceFontSize")
  return (
    <Block position={"relative"}>
      <GatsbyImage
        image={product.Image.childImageSharp.fluid}
        height={[200, 500]}
        imageStyle={{ objectFit: "cover" }}
      />
      <Block
        position={"absolute"}
        width={"80%"}
        textAlign={"center"}
        bottom={"3.5%"}
        my={"auto"}
        left={0}
        right={0}
      >
        <Text color={"white"} fontWeight={"bold"} fontSize={nameFontSize}>
          {product[`Name_${locale}`]}
        </Text>
        {product.Price && (
          <Text color={"white"} fontWeight={"bold"} fontSize={priceFontSize}>
            {product.Price} THB
          </Text>
        )}
      </Block>
    </Block>
  )
}

export default withIntl()(ProductCard)

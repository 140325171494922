import React from "react"
import { Block } from "components/atoms"
import Slider from "react-slick"
import styled from "@emotion/styled"
import IconButton from "@material-ui/core/IconButton"

import ArrowBackIos from "@material-ui/icons/ArrowBackIosOutlined"
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIosOutlined"
import omit from "lodash/omit"
import ReactResizeDetector from "react-resize-detector"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const StyledIconButton = styled(IconButton)({
  "&.slider__arrow": {
    position: "absolute",
    transform: "translateY(-50%)",
    zIndex: 100,
    padding: "0.7rem",
  },
  ".MuiSvgIcon-root": {
    fontSize: "1em",
  },
  ".MuiIconButton-root:hover": {
    backgroundColor: "white",
  },
  "@media (hover: none)": {
    ".MuiIconButton-root:hover": {
      backgroundColor: "white",
    },
  },
})

const Arrow = ({ icon, direction, onClick }) => (
  <StyledIconButton
    className={`slider__arrow slider__arrow--${direction}`}
    onClick={onClick}
  >
    {icon}
  </StyledIconButton>
)

const Slick = props => {
  const {
    settings,
    children,
    dotsBottomPosition,
    leftArrowPos,
    rightArrowPos,
    ArrowMobilePos,
    setGetSlickWidth,
    dotsColor,
    slider,
    arrowColor,
    verticalArrowPos,
    boxShadow = "none",
  } = props
  const defaultSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <Arrow direction={"right"} icon={<ArrowForwardIos />} />,
    prevArrow: <Arrow direction={"left"} icon={<ArrowBackIos />} />,
  }
  return (
    <Block
      css={{
        ".slick-dots": { bottom: dotsBottomPosition || 10 },
        ".slick-dots li button::before": {
          fontSize: 10,
          color: "transparent",
          opacity: 1,
          border: "1px solid",
          borderColor: dotsColor || "white",
          borderRadius: "50%",
          width: 10,
          height: 10,
          background: "transparent",
        },
        ".slick-list": {
          paddingBottom: 10,
        },
        ".slick-dots li.slick-active button::before": {
          fontSize: 10,
          opacity: 1,
          background: dotsColor || "white",
        },
        ".slider__arrow--left": {
          left: ArrowMobilePos || -14,
          color: arrowColor,
        },
        ".slider__arrow--right": {
          right: ArrowMobilePos || -14,
          color: arrowColor,
        },
        "@media (min-width: 640px)": {
          ".slider__arrow--left": {
            left: leftArrowPos,
          },
          ".slider__arrow--right": {
            right: rightArrowPos,
          },
        },
        ".slider__arrow": {
          background: "white",
          boxShadow,
          top: (verticalArrowPos && verticalArrowPos[1]) || "50%",
          "@media (max-width: 640px)": {
            top: verticalArrowPos && verticalArrowPos[0],
            fontSize: "1em",
          },
        },
        "@media (hover: none)": {
          ".MuiIconButton-root:hover": {
            backgroundColor: "white",
          },
        },
      }}
      {...omit(props, [
        "settings",
        "children",
        "dotsBottomPosition",
        "rightArrowPos",
        "leftArrowPos",
        "setGetSlickWidth",
        "boxShadow",
      ])}
    >
      {setGetSlickWidth && (
        <ReactResizeDetector
          handleWidth
          refreshMode={"throttle"}
          onResize={width => setGetSlickWidth(width)}
        />
      )}
      <Slider {...defaultSettings} {...settings} ref={slider}>
        {children}
      </Slider>
    </Block>
  )
}

export default Slick

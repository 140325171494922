import React from "react"
import PropTypes from "prop-types"
import CircularProgress from "@material-ui/core/CircularProgress"
import { StaticQuery, graphql } from "gatsby"

import { Block, Text, GatsbyImage } from "components/atoms"
import { css } from "@emotion/core"

const Loader = ({ text }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiTheme(Name: { eq: "Theme" }) {
            Logo {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { Logo } = data.strapiTheme
        return (
          <Block
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            position={"absolute"}
            top={0}
            left={0}
            width={"100%"}
            height={"100%"}
            background={"rgba(255, 255, 255, 0.87)"}
            zIndex={100}
          >
            <Block position={"relative"}>
              <CircularProgress size={100} thickness={1} />
              <GatsbyImage
                image={Logo.childImageSharp.fluid}
                width={65}
                height={65}
                position={"absolute"}
                top={"50%"}
                left={"50%"}
                css={css`transform: translate(-49%, -53%)}`}
              />
            </Block>
            <Text
              color={"darkGrey"}
              letterSpacing={"1px"}
              fontWeight={"light"}
              mt={12}
              fontSize={2}
            >
              {text}
            </Text>
          </Block>
        )
      }}
    />
  )
}

Loader.propTypes = {
  float: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
}
Loader.defaultProps = {
  float: true,
  className: "",
  text: "Loading...",
}

export default Loader

import React from "react"
import { IntlConsumer } from "../lang/IntlContext"

export default () => WrappedComponent => props => (
  <IntlConsumer>
    {({ switchLanguage, locale, messages }) => {
      return (
        <WrappedComponent
          {...props}
          switchLanguage={switchLanguage}
          locale={locale}
          messages={messages[locale]}
        />
      )
    }}
  </IntlConsumer>
)

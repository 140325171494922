import React from 'react';

// COMPONENTS
import { CouponsSection  ,SurveyLists} from 'components/molecules';
import {  Block, Text ,Loader } from 'components/atoms';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';

// REDUX
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { getAllNotifications } from 'modules/notification/actions';
import {
  selectData,
  selectCoupons,
  selectShouldGetMoreAllNotification,
  selectGetAllNotificationStatus
} from 'modules/notification/selectors';

// HOC
import withTheme from 'hocs/withTheme';

const StyledBlock = styled('div')({});

var load = 0;
class NotificationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSurveys: true,
      hasMore: true,
      type: 'notification'
    };
  }

  fetchMoreData = () => {
    this.setState({ hasMore: false });
    this.props.getAllNotifications().then(data => {
      if (data.value.length < 6) this.setState({ hasMore: false });
      else this.setState({ hasMore: true });
    });
  };

  selectCategory = category => {
    this.setState({ type: category });
  };

  renderTextButton = category => {
    return (
      <Block
        width={'100%'}
        css={{
          cursor: 'pointer',
          ':hover > *': {
            color: '#f89202 !important'
          }
        }}
        justifyContent={'center'}
        onClick={() => this.selectCategory(category)}
        borderRight={
          this.state.type === category
            ? [null, '3px solid #f89202']
            : [null, '3px solid lightgrey']
        }
        borderBottom={
          this.state.type === category
            ? ['3px solid #f89202', 'none']
            : ['3px solid lightgrey', 'none']
        }>
        <Text
          color={this.state.type === category ? '#f89202 !important' : 'grey'}
          style={{
            backgroundColor: this.state.type === category && '#fff7ee'
          }}
          fontSize={[20, 25, 30]}
          py={10}
          fontWeight={500}
          textAlign={'center'}>
          {category}
        </Text>
      </Block>
    );
  };

  renderList = () => {
    const { type } = this.state;
    const { notifications} = this.props;
    if (type === 'coupon') {
      return <CouponsSection />;
    }
    // return notifications.length > 0 ? (
    //   <SurveyLists surveys={notifications} />
    // ) : (
    //   <NoResult />
    // );
    return notifications.length > 0 && <SurveyLists surveys={notifications} />
  };

  render() {
    const { getAllNotificationStatus } = this.props;
    if (getAllNotificationStatus.isFulfilled) load = 1;
    return (
      <StyledBlock>
        <Block.Flex
          justifyContent={'center'}
          flexDirection={['column', 'column', 'row']}
          p={20}>
          <Grid container justify={'center'}>
            <Grid item xs={12} md={3} lg={3}>
              <Block display={['flex', 'block']}>
                {this.renderTextButton('notification')}
                {this.renderTextButton('coupon')}
              </Block>
            </Grid>
            {load === 0 && <Loader />}
            <Grid item xs={12} md={9} lg={9}>
              <InfiniteScroll
                pageStart={0}
                loadMore={() => this.fetchMoreData()}
                hasMore={this.state.hasMore}
                loader={
                  <Block.Flex key={'loader'} justifyContent={'center'} p={20}>
                    <CircularProgress size={20} thickness={5} />
                  </Block.Flex>
                }>
                <Block />
                {this.renderList()}
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Block.Flex>
      </StyledBlock>
    );
  }
}

export default compose(
  connect(
    state => ({
      notifications: selectData(state),
      coupons: selectCoupons(state),
      selectShouldGetMoreAllNotification: selectShouldGetMoreAllNotification(
        state
      ),
      getAllNotificationStatus: selectGetAllNotificationStatus(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          getAllNotifications
        },
        dispatch
      )
  )
)(withTheme()(NotificationSection));

import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from 'components/atoms';
import { Alert } from 'components/molecules';
import TextField from '@material-ui/core/TextField';

import { displayError } from '../../helpers/functions';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

class EmailSignIn extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  constructor() {
    super();
    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();
    this.state = {
      loading: false,
      errorCode: null
    };
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.mounted) {
      const { onSubmit } = this.props;
      if (this.emailInput && this.passwordInput) {
        const email = this.emailInput.current.value;
        const password = this.passwordInput.current.value;
        return this.setState({ loading: true }, () =>
          onSubmit(email, password)
            .then(() => this.setState({ loading: false }))
            .catch(error =>
              this.setState({ errorCode: error.code, loading: false })
            )
        );
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { loading, errorCode } = this.state;
    return (
      <React.Fragment>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <FormControl variant={'filled'} margin="dense" required fullWidth>
            <TextField
              inputRef={this.emailInput}
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              autoFocus
              placeholder={'example@gmail.com'}
            />
          </FormControl>
          <FormControl variant={'filled'} margin="dense" required fullWidth>
            <TextField
              inputRef={this.passwordInput}
              name="password"
              type="password"
              id="password"
              label="Password"
              autoComplete="current-password"
              placeholder={'โปรดกรอก 6 ตัวอักษรขึ้นไป'}
            />
          </FormControl>
          <Button.Contain
            type="submit"
            color={'primary'}
            fullWidth
            className={classes.submit}>
            เข้าสู่ระบบ
          </Button.Contain>
        </form>
        <Alert.Processing
          open={loading}
          title={'โปรดรอสักครู่'}
          description={'กำลังเข้าสู่ระบบจ้า...'}
        />
        <Alert
          open={!!errorCode}
          status={'danger'}
          title={displayError(errorCode)}
          onClose={() => this.setState({ errorCode: null })}
        />
      </React.Fragment>
    );
  }
}

const StyledEmailSignIn = withStyles(styles)(EmailSignIn);

StyledEmailSignIn.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default StyledEmailSignIn;
import React from "react"
import { Text, Block } from "components/atoms"
import { Link, navigate } from "gatsby"
import { scroller } from "react-scroll"

// Lodash
import get from "lodash/get"

const SubMenu = ({
  Action,
  content,
  TextColor,
  closeAfter = () => null,
  theme,
}) => {
  const primary = get(theme, "colors.primary")
  const renderLinkSubmenu = () => {
    const externalPaths = ["http", "mailto", "tel"]
    const external = externalPaths.some(externalPath =>
      Action.startsWith(externalPath)
    )

    if (external) {
      return (
        <a
          href={Action}
          target="_blank"
          rel={Action.startsWith("http") && "noopener noreferrer"}
        >
          <Text color={TextColor} variant={"subtitle1"}>
            {content}
          </Text>
        </a>
      )
    }
    if (Action.startsWith("#")) {
      const redirect = () => {
        !!document.getElementById(Action.slice(1))
          ? scroller.scrollTo(Action.slice(1), {
            duration: 500,
            smooth: true,
            offset: -50,
          })
          : navigate("/", {
            state: { scrollTo: Action.slice(1) },
          })
        closeAfter && closeAfter()
      }
      return (
        <Text onClick={redirect} color={TextColor} variant={"subtitle1"}>
          {content}
        </Text>
      )
    }
    return (
      <Link to={Action} style={{ textDecoration: "none" }}>
        <Text color={TextColor} variant={"subtitle1"}>
          {content}
        </Text>
      </Link>
    )
  }
  if (Action) {
    return (
      <Block
        my={[15, 10]}
        css={{
          textDecoration: "none",
          width: "fit-content",
          cursor: "pointer",
          "&:hover": {
            ".MuiTypography-root": {
              color: primary,
            },
          },
        }}
      >
        {renderLinkSubmenu()}
      </Block>
    )
  }
  return (
    <Text color={TextColor} variant={"subtitle1"} onClick={() => closeAfter()} my={[15, 10]}>
      {content}
    </Text>
  )
}

export default SubMenu

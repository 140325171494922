import axios from "axios"
import firebase from "../firebase/config"
import { HOST } from "../helpers/constants"

export default class CloudApi {
  static request(config) {
    return axios({
      method: "GET",
      ...config,
      baseURL: `${HOST}/api`,
      headers: {
        "Content-Type": "application/json",
        ...config.headers,
      },
    })
      .then(({ data }) => data)
      .catch(({ response, ...other }) => {
        // console.log('response', response)
        if (response) {
          throw response.data
        }
        // eslint-disable-next-line
        throw { error: other }
      })
  }

  static requestWithCredential(config) {
    const user = firebase.auth().currentUser
    if (user) {
      return user.getIdToken().then(idToken => {
        return CloudApi.request({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${idToken}`,
          },
        })
      })
    } else {
      throw new Error("Not login")
    }
    // return auth.currentUser.getIdToken().then(idToken => {
    //   // console.log('idToken', idToken)
    //   return CloudApi.request({
    //     ...config,
    //     headers: {
    //       ...config.headers,
    //       Authorization: `Bearer ${idToken}`
    //     }
    //   });
    // });
  }

  static getProfile(path) {
    return CloudApi.requestWithCredential({
      url: `/users/${process.env.BRAND_ID}/me${path ? `?path=${path}` : ""}`,
    })
  }

  static registerWithFacebook(data) {
    return CloudApi.requestWithCredential({
      method: "POST",
      url: "/users/register/facebook",
      data,
    })
  }

  static register(data) {
    return CloudApi.request({
      url: "/users/register/email",
      method: "POST",
      data,
    })
  }

  static loginFacebook(data) {
    return CloudApi.requestWithCredential({
      method: "POST",
      url: "/users/login/facebook",
      data,
    })
  }

  static loginWithGuest(data) {
    // console.log('data', data);
    return CloudApi.requestWithCredential({
      method: "POST",
      url: "/users/login/guest",
      data,
    })
  }

  static loginWithLine(data, accessToken) {
    return CloudApi.request({
      method: "POST",
      url: "/users/login/line",
      headers: {
        Authorization: accessToken,
      },
      data,
    })
  }

  static getRewards(brandId) {
    return CloudApi.request({
      url: `/brands/${brandId}/rewards`,
    })
  }

  static getSurveys(brandId, surveyId) {
    return CloudApi.requestWithCredential({
      url: `/brands/${brandId}/surveys/${surveyId}/detail`,
    })
  }

  static getAllSurveys() {
    return CloudApi.requestWithCredential({
      url: "/users/surveys",
    })
  }

  static submitAnswer(brandId, surveyId, data) {
    return CloudApi.requestWithCredential({
      method: "POST",
      url: `/brands/${brandId}/campaigns/${surveyId}/responses`,
      data,
    })
  }

  static getLatestSurvey(brandId) {
    return CloudApi.requestWithCredential({
      url: `/brands/${brandId}/latest-survey`,
    })
  }

  static addUserCardAndSurvey(brandId, surveyId) {
    return CloudApi.requestWithCredential({
      url: `/brands/${brandId}/surveys/${surveyId}/users`,
    })
  }

  static getAllBrands() {
    return CloudApi.request({
      url: "/brands",
    })
  }

  static getCategories() {
    return CloudApi.request({
      url: "/categories",
    })
  }

  static addContractToGoogleSheet(data) {
    return CloudApi.request({
      url: "/google-sheet",
      method: "POST",
      data,
    })
  }

  static getCoupons({ lastMoment, brandId }) {
    return CloudApi.requestWithCredential({
      url: `/notifications/${brandId}/me?type=coupon${
        lastMoment ? `&after=${lastMoment}` : ""
      }`,
    })
  }

  static getPlans() {
    return CloudApi.request({
      url: "/plans",
    })
  }

  static getPrivileges() {
    return CloudApi.request({
      url: "/brands/reward-random",
    })
  }

  static getAllNotifications(lastMoment) {
    return CloudApi.requestWithCredential({
      url: `/notifications/all/me?${lastMoment ? `after=${lastMoment}` : ""}`,
    })
  }

  static getNewNotification(id) {
    return CloudApi.requestWithCredential({
      url: `/notifications/${id}`,
    })
  }

  static updateProfile(data) {
    return CloudApi.requestWithCredential({
      url: "/users/me/basic-profile",
      method: "PUT",
      data,
    })
  }

  static getBrandById() {
    return CloudApi.request({
      url: `/brands/${process.env.BRAND_ID}/info`,
    }).then(data => {
      return data
    })
  }
}

/** @jsx jsx */
import React from "react"
import { Block, Text, GatsbyImage } from "components/atoms"
import GoogleMapReact from "google-map-react"
import { Info, Directions } from "@material-ui/icons"
import { StaticQuery, graphql } from "gatsby"
import { jsx, css } from "@emotion/core"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

// Lodash
import get from "lodash/get"

const StoreMap = ({
  store,
  allStores,
  setActiveStore,
  activeStore,
  zoom,
  setZoom,
  slider,
  calculateSlideTo,
  theme,
}) => {
  const { displayName = null, lat, lng, location, information } = store
  const [center, setCenter] = React.useState({ lat, lng })
  const primary = get(theme, "colors.primary")
  // const { informationFontSize, locationFontSize } = pick(theme.StoreMap, [
  //   "informationFontSize",
  //   "locationFontSize",
  // ])
  return (
    <StaticQuery
      query={graphql`
        query PinImage {
          strapiTheme(Name: { eq: "Theme" }) {
            PinImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={data => {
        const PinImage = data.strapiTheme.PinImage
        return (
          <Block display={["block", "block", "flex"]}>
            <Block height={[160, 160, 300]} width={["100%", "100%", "70%"]}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAQBR-TO4oUCFMgYVAleeMr00QDn5eM3Nc",
                }}
                options={
                  {
                    // disableDefaultUI: true,
                    // scaleControl: false,
                    // zoomControl: false,
                    // scrollwheel: false,
                  }
                }
                center={{
                  lat: parseFloat(lat),
                  lng: parseFloat(lng),
                }}
                zoomControl={false}
                zoom={zoom}
                onChange={({ zoom, center }) => {
                  setCenter({ lat: center.lat, lng: center.lng })
                  setZoom(zoom)
                }}
              >
                {allStores.map((store, index) => (
                  <Block
                    lat={parseFloat(store.lat)}
                    lng={parseFloat(store.lng)}
                    position={"absolute"}
                    css={{
                      transform: "translate(-50%,-0%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setActiveStore(index)
                      if (activeStore !== index) {
                        setZoom(15)
                      }
                      slider.current.slickGoTo(calculateSlideTo(index))
                    }}
                  >
                    <GatsbyImage
                      image={PinImage.childImageSharp.fluid}
                      width={[35, 60]}
                      height={[35, 60]}
                    />
                  </Block>
                ))}
              </GoogleMapReact>
            </Block>
            <Block
              px={20}
              py={10}
              width={["auto", "auto", "30%"]}
              borderRadius={[
                "0px 0px 8px 8px",
                "0px 0px 8px 8px",
                "0px 8px 8px 0px",
              ]}
              height={["auto", "auto", 280]}
              overflow={"auto"}
              boxShadow={"0 2px 14px 0 rgba(0, 0, 0, 0.12)"}
            >
              <Block
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Text variant={"h5"}>
                  {displayName}
                </Text>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`}
                  target="_blank"
                  // rel={"noopener noreferrer"}
                  css={css`
                    color: inherit;
                    font-size: 2rem;
                    &:hover {
                      color: ${primary};
                    }
                  `}
                >
                  <Directions />
                </a>
              </Block>
              <Text variant={"subtitle2"} mt={["5px", "15px", "10px"]}>
                {location}
              </Text>
              <Block mt={[18, 30]}>
                <Info />
                <Text variant={"subtitle1"}>{information}</Text>
              </Block>
            </Block>
          </Block>
        )
      }}
    />
  )
}

export default withEmotion(StoreMap)

import React, { useEffect } from "react"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepButton from "@material-ui/core/StepButton"
import { Block, Image, Text } from "components/atoms"
import { css } from "@emotion/core"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { Check } from "@material-ui/icons"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { selectRewards } from "modules/reward/selectors"
import { getRewards } from "modules/reward/actions"
import { selectBrands } from "modules/brand/selectors"
import { getBrandById } from "modules/brand/actions"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

const CardReward = ({
  brand,
  userPoint = 0,
  brandBackend,
  rewardsBackend,
  getBrandById,
  getRewards,
  brandId,
}) => {
  useEffect(() => {
    if (!brandBackend || !brandBackend.length) {
      getBrandById(brandId)
    }
    if (!rewardsBackend || !rewardsBackend.length) {
      getRewards(brandId)
    }
  }, [])
  const primaryColor =
    get(brandBackend, "primaryColor", null) ||
    get(brand, "primaryColor", "#FFF")
  const rewards = !isEmpty(rewardsBackend)
    ? rewardsBackend[brandId]
    : get(brand, "rewards", [])

  const unRedeemAbleIndex = rewards.findIndex(({ point }) => point > userPoint)
  const [activeStep, setActiveStep] = React.useState(0)
  const handleStep = step => () => {
    setActiveStep(step)
  }
  const progressDot = () => (
    <Block
      position={"absolute"}
      top={4}
      left={0}
      css={{
        "&>div": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "50%",
          backgroundColor: primaryColor,
        },
      }}
    >
      <Block width={108} height={108} opacity={0.04} />
      <Block width={56} height={56} opacity={0.11} />
      <Block width={15} height={15} />
    </Block>
  )

  const overRide = css`
    .MuiStepButton-root {
      z-index: 1;
      padding: 8px 16px;
    }

    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      margin-top: 0;
    }

    ,
    .MuiStepLabel-label.MuiStepLabel-active .pumpkin-text {
      color: black;
    }

    ,
    .MuiStepConnector-alternativeLabel {
      top: 3px;
      left: calc(-50%);
      right: calc(50%);
    }

    ,
    .MuiStepper-root {
      padding: 24px 12px;
    }

    ,
    .MuiStepConnector-lineHorizontal {
      border-color: ${primaryColor};
      border-top-width: 2px;
    }

    ,
    .MuiStepLabel-iconContainer {
      z-index: 1;
    }

    ,
    .MuiPaper-root {
      background-color: transparent;
    }
  `
  return (
    <Block css={overRide}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {rewards.length > 0 &&
          rewards.map(({ id, point, image }, index) => {
            const stepProps = {}
            const buttonProps = {}
            return (
              <Step key={id} {...stepProps}>
                <StepButton
                  onClick={handleStep(index)}
                  {...buttonProps}
                  icon={
                    <Block
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={39}
                      height={39}
                    >
                      <Block position={"relative"}>
                        <Image
                          src={image}
                          objectFit={"cover"}
                          circular
                          height={activeStep === index ? 37 : 30}
                          width={activeStep === index ? 37 : 30}
                          background={"white"}
                          border={`2px solid ${primaryColor}`}
                          borderRadius={"50%"}
                        />
                        {(unRedeemAbleIndex > index ||
                          unRedeemAbleIndex === -1) && (
                            <Block
                              display={"flex"}
                              position={"absolute"}
                              top={"50%"}
                              left={"50%"}
                              css={{ transform: "translate(-50%, -50%)" }}
                              color={"white"}
                              width={"80%"}
                              height={"80%"}
                            >
                              <Check style={{ width: "100%", height: "100%" }} />
                            </Block>
                          )}
                      </Block>
                    </Block>
                  }
                >

                  <Block display={['none', 'block']}>
                    <Text color={"grey"} mb={0}>
                      {`${point} Points`}
                    </Text>
                  </Block>
                  <Block display={['block', 'none']}>
                    <Text color={"grey"} mb={0}>
                      {`${point} P`}
                    </Text>
                  </Block>
                </StepButton>
                {userPoint !== 0 &&
                  index !== 0 &&
                  unRedeemAbleIndex === index &&
                  progressDot()}
              </Step>
            )
          })}
      </Stepper>
      {rewards.length > 0 && (
        <Block.Flex
          px={12}
          py={24}
          mx={"auto"}
          mt={[12, 25]}
          maxWidth={670}
          borderRadius={8}
          boxShadow={" 0 2px 14px 0 rgba(0, 0, 0, 0.05)"}
          backgroundColor={"white"}
        >
          <Block alignSelf={"center"} maxWidth={117} minWidth={95}>
            <Image
              src={rewards[activeStep].image}
              borderRadius={8}
              m={"auto"}
              width={"100%"}
              height={[75, 100]}
              objectFit={"cover"}
            />
          </Block>
          <Block.Flex
            flexDirection={"column"}
            ml={31}
            style={{ wordWrap: "break-word" }}
          >
            <Text variant={"h5"}>
              {rewards[activeStep].title}
            </Text>
            <Text variant={"subtitle1"} textAlign={'left'}>
              {rewards[activeStep].description}
            </Text>
          </Block.Flex>
        </Block.Flex>
      )}
    </Block>
  )
}

export default connect(
  state => ({
    brandBackend: selectBrands(state),
    rewardsBackend: selectRewards(state),
  }),
  dispatch =>
    bindActionCreators(
      {
        getRewards,
        getBrandById,
      },
      dispatch
    )
)(withEmotion(CardReward))
